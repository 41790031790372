/**
 * Created by apop on 06/09/2018.
 */
angular
    .module('annexaApp')
    .component('annexaDossierEmgde', {
        templateUrl: './components/tram/annexa-dossier-emgde/annexa-dossier-emgde.html',
        controller: ['Language', 'TramNewFactory','$rootScope', 'GlobalDataFactory', '$filter', 'globalModals', 'AnnexaFormlyFactory', 'DialogsFactory', 'AnnexaPermissionsFactory', function (Language, TramNewFactory, $rootScope, GlobalDataFactory, $filter, globalModals, AnnexaFormlyFactory, DialogsFactory, AnnexaPermissionsFactory) {
            var vm = this;
            vm.languageColumn = Language.getActiveColumn();
			vm.isTransferedMode = false;
			vm.metadata_entity_type = false;
			vm.metadata_category = false;
			vm.metadata_identifier_sequence = false;
			vm.metadata_name_natural = false;
			vm.metadata_nti_version = false;
			vm.metadata_dossier_status = false;
			vm.metadata_date_start_date_update = false;
			vm.metadata_date_start_date_delete = false;
			vm.metadata_date_start_date_deleteFirst = false;
			vm.metadata_date_start_date_create = false;
			vm.metadata_dates_start_date = false;
			vm.metadata_dates_end_date = false;
			vm.metadata_security_update = false;
			vm.metadata_security_delete = false;
			vm.metadata_security_deleteFirst = false;
			vm.metadata_security_create = false;
			vm.metadata_access_type = false;
			vm.metadata_limit_cause_update = false;
			vm.metadata_limit_cause_delete = false;
			vm.metadata_limit_cause_deleteFirst = false;
			vm.metadata_limit_cause_create = false;
			vm.metadata_legal_limit_cause_update = false;
			vm.metadata_legal_limit_cause_delete = false;
			vm.metadata_legal_limit_cause_deleteFirst = false;
			vm.metadata_legal_limit_cause_create = false;
			vm.metadata_reuse_condition = false;
			vm.metadata_language_language_update = true;
			vm.metadata_language_language_delete = false;
			vm.metadata_language_language_deleteFirst = false;
			vm.metadata_language_language_create = false;
			vm.metadata_value_type = false;
			vm.metadata_terms = false;
			vm.metadata_secundary_value = false;
			vm.metadata_opinion_update = false;
			vm.metadata_opinion_delete = false;
			vm.metadata_opinion_deleteFirst = false;
			vm.metadata_opinion_create = false;
			vm.metadata_opinion_type_update = false;
			vm.metadata_opinion_type_delete = false;
			vm.metadata_opinion_type_deleteFirst = false;
			vm.metadata_opinion_type_create = false;
			vm.metadata_opinion_action_update = false;
			vm.metadata_opinion_action_delete = false;
			vm.metadata_opinion_action_deleteFirst = false;
			vm.metadata_opinion_action_create = false;
			vm.metadata_opinion_terms = false;
			vm.metadata_transfer_update = false;
			vm.metadata_transfer_delete = false;
			vm.metadata_transfer_deleteFirst = false;
			vm.metadata_transfer_create = false;
			vm.metadata_transfer_archive = false;
			vm.metadata_transfer_terms = false;
			vm.metadata_essential_document = false;
			vm.metadata_location_support_update = true;
			vm.metadata_location_support_delete = false;
			vm.metadata_location_support_deleteFirst = false;
			vm.metadata_location_support_create = false;
			vm.metadata_location_localization_update = true;
			vm.metadata_location_localization_delete = false;
			vm.metadata_location_localization_deleteFirst = false;
			vm.metadata_location_localization_create = false;
			vm.metadata_signature_update = false;
			vm.metadata_signature_delete = false;
			vm.metadata_signature_deleteFirst = false;
			vm.metadata_signature_create = false;
			vm.metadata_signature_format = false;
			vm.metadata_signature_profile = false;
			vm.metadata_classification_update = false;
			vm.metadata_classification_delete = false;
			vm.metadata_classification_deleteFirst = false;
			vm.metadata_classification_create = false;
			vm.metadata_classification_code = false;
			vm.metadata_classification_name = false;
			vm.metadata_classification_type = false;
			vm.metadata_organ_update = false;
			vm.metadata_organ_delete = false;
			vm.metadata_organ_deleteFirst = false;
			vm.metadata_organ_create = false;
			vm.metadata_organ_organ = false;
			vm.metadata_interested_update = false;
			vm.metadata_interested_delete = false;
			vm.metadata_interested_deleteFirst = false;
			vm.metadata_interested_create = false;
          	vm.isDossierEniPresent = false;
          	vm.metadata_security_classification = false;
			
			vm.getLiteral = function(type, value){
				var literal = '';
				if(type){
					if(type == 'entityType'){
						if(value){
							var literalAux = $linq(GlobalDataFactory.emgdeLists.entityTypes).firstOrDefault(undefined,"x => x.id == '"+value+"'");
							if(literalAux && literalAux.description){
								literal = $filter('translate')(literalAux.description);
							}
						}
					} else if(type == 'category'){
						if(value){
							var literalAux = $linq(GlobalDataFactory.emgdeLists.categories).firstOrDefault(undefined,"x => x.id == '"+value+"'");
							if(literalAux && literalAux.description){
								literal = $filter('translate')(literalAux.description);
							}
						}
					} else if(type == 'units'){
						if(value){
							var literalAux = $linq(GlobalDataFactory.emgdeLists.units).firstOrDefault(undefined,"x => x.id == '"+value+"'");
							if(literalAux && literalAux.description){
								literal = $filter('translate')(literalAux.description);
							}
						}
					} else if(type == 'signatureFormats'){
						if(value){
							var literalAux = $linq(GlobalDataFactory.emgdeLists.signatureFormats).firstOrDefault(undefined,"x => x.id == '"+value+"'");
							if(literalAux && literalAux.description){
								literal = $filter('translate')(literalAux.description);
							}
						}
					} else if(type == 'documentOrigin'){
						if(value){
							var literalAux = $linq(GlobalDataFactory.emgdeLists.documentOrigins).firstOrDefault(undefined,"x => x.id == '"+value+"'");
							if(literalAux && literalAux.description){
								literal = $filter('translate')(literalAux.description);
							}
						}
					} else if(type == 'classificationType'){
						if(value){
							var literalAux = $linq(GlobalDataFactory.emgdeLists.classificationTypes).firstOrDefault(undefined,"x => x.id == '"+value+"'");
							if(literalAux && literalAux.description){
								literal = $filter('translate')(literalAux.description);
							}
						}
					} else if(type == 'securityLevel'){
						if(value){
							var literalAux = $linq(GlobalDataFactory.emgdeLists.securityLevels).firstOrDefault(undefined,"x => x.id == '"+value+"'");
							if(literalAux && literalAux.description){
								literal = $filter('translate')(literalAux.description);
							}
						}
					} else if(type == 'accesType'){
						if(value){
							var literalAux = $linq(GlobalDataFactory.emgdeLists.accesTypes).firstOrDefault(undefined,"x => x.id == '"+value+"'");
							if(literalAux && literalAux.description){
								literal = $filter('translate')(literalAux.description);
							}
						}
					} else if(type == 'limitCause'){
						if(value){
							var literalAux = $linq(GlobalDataFactory.emgdeLists.limitCauses).firstOrDefault(undefined,"x => x.id == '"+value+"'");
							if(literalAux && literalAux.description){
								literal = $filter('translate')(literalAux.description);
							}
						}
					} else if(type == 'valueType'){
						if(value){
							var literalAux = $linq(GlobalDataFactory.emgdeLists.valueTypes).firstOrDefault(undefined,"x => x.id == '"+value+"'");
							if(literalAux && literalAux.description){
								literal = $filter('translate')(literalAux.description);
							}
						}
					} else if(type == 'terms'){
						if(value){
							literal = value;
						}
					} else if(type == 'secundaryValue'){
						if(value){
							var literalAux = $linq(GlobalDataFactory.emgdeLists.secundaryValues).firstOrDefault(undefined,"x => x.id == '"+value+"'");
							if(literalAux && literalAux.description){
								literal = $filter('translate')(literalAux.description);
							}
						}
					} else if(type == 'essentialDocument'){
						if(value){
							literal = $filter('translate')('DIALOGS_YES')
						}else{
							literal = $filter('translate')('DIALOGS_NO')
						}
					} else if(type == 'archive'){
						if(value){
							var literalAux = $linq(GlobalDataFactory.emgdeLists.archiveFases).firstOrDefault(undefined,"x => x.id == '"+value+"'");
							if(literalAux && literalAux.description){
								literal = $filter('translate')(literalAux.description);
							}
						}
					} else if(type == 'type'){
						if(value){
							var literalAux = $linq(GlobalDataFactory.emgdeLists.opinionTypes).firstOrDefault(undefined,"x => x.id == '"+value+"'");
							if(literalAux && literalAux.description){
								literal = $filter('translate')(literalAux.description);
							}
						}
					} else if(type == 'dossierStatus'){
						if(value){
							var literalAux = $linq(GlobalDataFactory.emgdeLists.dossierStatuses).firstOrDefault(undefined,"x => x.id == '"+value+"'");
							if(literalAux && literalAux.description){
								literal = $filter('translate')(literalAux.description);
							}
						}
					} else if(type == 'locationSupport'){
						if(value && value.id){
							var literalAux = $linq(GlobalDataFactory.emgdeListsSupports).firstOrDefault(undefined,"x => x.id == '"+value.id+"'");
							if(literalAux && literalAux[vm.languageColumn]){
								literal = literalAux[vm.languageColumn];
							}
						}
					} else if(type == 'securityClassification'){
						if(value){
							var literalAux = $linq(GlobalDataFactory.emgdeLists.securityClassifications).firstOrDefault(undefined,"x => x.id == '"+value+"'");
							if(literalAux && literalAux.description){
								literal = $filter('translate')(literalAux.description);
							}
						}
					}
				}	
				return literal;
			}
			
			vm.updateEMGDE = function(fieldName){
				if(fieldName){
					var fields = [];
	            	var data = {row: true,colClass: ' col-sm-12',labelClass: 'label-strong'};
	                var modal = angular.copy(globalModals.emgdeModal);
	                modal.annexaFormly = new AnnexaFormly();
	                modal.annexaFormly.addGroup('modal_body','modal-body p-lg',fields);
	                modal.annexaFormly.model = {};
	                modal.annexaFormly.model.modal_body = {};
					modal.annexaFormly.model.modal_body[fieldName] = angular.copy(vm.emgdeDossierMetadata[fieldName]);
					modal.annexaFormly.options = {};
	                modal.annexaFormly.options.formState = {readOnly: false};
	                modal.alerts = [];
					if(fieldName == 'entityType'){
						var field = modal.annexaFormly.createField(fieldName, 'annexaSelectRow', 'col-sm-12', new AnnexaFormlyFieldSelectTemplateOptions('global.documents.EMGDE.'+fieldName,'id','description',GlobalDataFactory.emgdeLists.entityTypes,true),data);                
						fields.push(field);
					}else if(fieldName == 'category'){
						var field = modal.annexaFormly.createField(fieldName, 'annexaSelectRow', 'col-sm-12', new AnnexaFormlyFieldSelectTemplateOptions('global.documents.EMGDE.'+fieldName,'id','description',GlobalDataFactory.emgdeLists.categories,true),data);                
						fields.push(field);
					}else if(fieldName == 'documentOrigin'){
						var field = modal.annexaFormly.createField(fieldName, 'annexaSelectRow', 'col-sm-12', new AnnexaFormlyFieldSelectTemplateOptions('global.documents.EMGDE.'+fieldName,'id','description',GlobalDataFactory.emgdeLists.documentOrigins,true),data);                
						fields.push(field);
					}else if(fieldName == 'accesType'){
						var field = modal.annexaFormly.createField(fieldName, 'annexaSelectRow', 'col-sm-12', new AnnexaFormlyFieldSelectTemplateOptions('global.documents.EMGDE.'+fieldName,'id','description',GlobalDataFactory.emgdeLists.accesTypes,true),data);                
						fields.push(field);
					}else if(fieldName == 'valueType'){
						var field = modal.annexaFormly.createField(fieldName, 'annexaSelectRow', 'col-sm-12', new AnnexaFormlyFieldSelectTemplateOptions('global.documents.EMGDE.'+fieldName,'id','description',GlobalDataFactory.emgdeLists.valueTypes,true),data);                
						fields.push(field);
					}else if(fieldName == 'terms'){
						var field = modal.annexaFormly.createField(fieldName,'annexaInputRow','col-sm-12',new AnnexaFormlyFieldTemplateOptions('number','global.documents.EMGDE.'+fieldName,true,true),data);                
						fields.push(field);
					}else if(fieldName == 'secundaryValue'){
						var field = modal.annexaFormly.createField(fieldName, 'annexaSelectRow', 'col-sm-12', new AnnexaFormlyFieldSelectTemplateOptions('global.documents.EMGDE.'+fieldName,'id','description',GlobalDataFactory.emgdeLists.secundaryValues,true),data);                
						if(field && field.templateOptions){
							field.templateOptions.labelPropAux = 'descriptionAux';
						}
						fields.push(field);
					}else if(fieldName == 'essentialDocument'){
						var field = modal.annexaFormly.createField(fieldName, 'annexaSelectRow', 'col-sm-12', new AnnexaFormlyFieldSelectTemplateOptions('global.documents.EMGDE.'+fieldName,'id','description',GlobalDataFactory.emgdeLists.essentialDocuments,true),data);                
						fields.push(field);
					}else if(fieldName == 'dossierStatus'){
						var field = modal.annexaFormly.createField(fieldName, 'annexaSelectRow', 'col-sm-12', new AnnexaFormlyFieldSelectTemplateOptions('global.documents.EMGDE.'+fieldName,'id','description',GlobalDataFactory.emgdeLists.dossierStatuses,true),data);                
						fields.push(field);
					}else if(fieldName == 'securityClassification'){
						var field = modal.annexaFormly.createField(fieldName, 'annexaSelectRow', 'col-sm-12', new AnnexaFormlyFieldSelectTemplateOptions('global.documents.EMGDE.'+fieldName,'id','description',GlobalDataFactory.emgdeLists.securityClassifications,true),data);                
						fields.push(field);
					}else{
						var field = modal.annexaFormly.createField(fieldName,'annexaInputRow','col-sm-12',new AnnexaFormlyFieldTemplateOptions('text','global.documents.EMGDE.'+fieldName,true,true,undefined,255),data);                
						fields.push(field);
					}
					var saveEMGDEDataConfirm = function(){
						var model = modal.annexaFormly.model.modal_body;
						if(model){
							var emgdeDocToSave = angular.copy(vm.emgdeDossierMetadata);
							emgdeDocToSave[fieldName] = model[fieldName];
		                	DialogsFactory.confirm("global.documents.EMGDE.updateEMGDE", "global.documents.EMGDE.updateEMGDEConfirm").then(function (dataAux) {
								TramNewFactory.updateMetadata(emgdeDocToSave).then(function(dataMetadata) {
		                    	if(dataMetadata){
		                    		vm.emgdeDossierMetadata = dataMetadata;
									modal.close();
		                    	}else{
		                    		modal.alerts.push({ msg: $filter('translate')('global.documents.EMGDE.errorUpdateEMGDE') });
		                    	}
		                    }).catch(function(error) {
		                        modal.alerts.push({ msg: $filter('translate')('global.documents.EMGDE.errorUpdateEMGDE') });
		                	});
		                    }).catch(function (data) {
		                    	//Empty
		                    });  
						}      	
					}
					AnnexaFormlyFactory.showModal("modalAddModifyDate", modal, saveEMGDEDataConfirm, false);
				}
			}
			
			vm.deleteDate = function(date) {
				if(vm.emgdeDossierMetadata && vm.emgdeDossierMetadata.dates){
					DialogsFactory.confirm("global.documents.EMGDE.updateEMGDE", "global.documents.EMGDE.deleteDateConfirm").then(function (dataAux) {
						TramNewFactory.deleteMetadataObj(date, vm.emgdeDossierMetadata.id, 'emgdeDossierMetadataDate').then(function(dataMetadata) {
							var index = $linq(vm.emgdeDossierMetadata.dates).indexOf("x => x.id == "+date);
							if(index >= 0){
								vm.emgdeDossierMetadata.dates.splice(index, 1);
							}
	                    }).catch(function(error) {
	                        DialogsFactory.error($filter('translate')('global.documents.EMGDE.errorDeleteEMGDE'));
	                	});
					}).catch(function (data) {
                    	//Empty
                    });  						
				}
			}
			
			vm.addDate = function(date){
				var fields = [];
            	var data = {row: true,colClass: ' col-sm-12',labelClass: 'label-strong'};
                var modal = angular.copy(globalModals.emgdeModal);
                modal.annexaFormly = new AnnexaFormly();
                modal.annexaFormly.addGroup('modal_body','modal-body p-lg',fields);
                modal.annexaFormly.model = {};
                modal.annexaFormly.model.modal_body = {};
				if(date){
					if(date.startDate){
						date.startDateAux = new Date(date.startDate);		
						date.startTimeAux = new Date(date.startDate);				
					}
					if(date.endDate){
						date.endDateAux = new Date(date.endDate);		
						date.endTimeAux = new Date(date.endDate);				
					}
					modal.annexaFormly.model.modal_body = angular.copy(date);
				}
                modal.annexaFormly.options = {};
                modal.annexaFormly.options.formState = {readOnly: false};
                modal.alerts = [];
				var field = undefined;
				if(vm.metadata_dates_start_date){
					field =  modal.annexaFormly.createField('startDateAux','annexaDatepickerRow','col-sm-7',new AnnexaFormlyFieldDatepickerTemplateOptions('text','global.documents.EMGDE.startDate',true,{format: 'dd/MM/yyyy',initDate: ((date && date.startDate)?new Date(date.startDate):new Date()),showWeeks: false,startingDay: 1}),data);
					fields.push(field);
					field = modal.annexaFormly.createField('startTimeAux','annexaTimepickerRow','col-sm-5',new AnnexaFormlyFieldDatepickerTemplateOptions('text','global.literals.time',true),data);
					fields.push(field);
				}else{
					field = modal.annexaFormly.createField('startDateAuxLabel', 'annexaLabelRow', 'col-sm-12',new AnnexaFormlyFieldLabelTemplateOptions('global.documents.EMGDE.startDate', ((date && date.startDateAux)?$filter('date')(date.startDateAux, 'dd-MM-yyyy HH:mm'):'')),data);
					fields.push(field);
				}
				if(vm.metadata_dates_end_date){
					field = modal.annexaFormly.createField('endDateAux','annexaDatepickerRow','col-sm-7',new AnnexaFormlyFieldDatepickerTemplateOptions('text','global.documents.EMGDE.endDate',true,{format: 'dd/MM/yyyy',initDate: ((date && date.endDate)?new Date(date.endDate):new Date()),showWeeks: false,startingDay: 1}),data);
					fields.push(field);
					field = modal.annexaFormly.createField('endTimeAux','annexaTimepickerRow','col-sm-5',new AnnexaFormlyFieldDatepickerTemplateOptions('text','global.literals.time',true),data);
					fields.push(field);
				}else{
					field = modal.annexaFormly.createField('startDateAuxLabel', 'annexaLabelRow', 'col-sm-12',new AnnexaFormlyFieldLabelTemplateOptions('global.documents.EMGDE.endDate', ((date && date.endDateAux)?$filter('date')(date.endDateAux, 'dd-MM-yyyy HH:mm'):'')),data);
					fields.push(field);
				}
				var saveDateConfirm = function(){
					var model = modal.annexaFormly.model.modal_body;
					if(model){
						 var startDate = ((model.startDateAux && model.startTimeAux)?new Date(Date.UTC(
							model.startDateAux.getFullYear(),
                            model.startDateAux.getMonth(),
                            model.startDateAux.getDate(),
                            model.startTimeAux.getUTCHours(),
                            model.startTimeAux.getUTCMinutes())):undefined);
						if(startDate){
							model.startDateAux = startDate;
						}
						var endDate = ((model.endDateAux && model.endTimeAux)?new Date(Date.UTC(
							model.endDateAux.getFullYear(),
                            model.endDateAux.getMonth(),
                            model.endDateAux.getDate(),
                            model.endTimeAux.getUTCHours(),
                            model.endTimeAux.getUTCMinutes())):undefined);
						if(endDate){
							model.endDateAux = endDate;
						}
	                	DialogsFactory.confirm("global.documents.EMGDE.updateEMGDE", "global.documents.EMGDE.saveDateConfirm").then(function (dataAux) {
							TramNewFactory.updateMetadataObj(model, vm.emgdeDossierMetadata.id, 'emgdeDossierMetadataDate').then(function(dataMetadata) {
		                    	if(dataMetadata && dataMetadata.dates){
		                    		vm.emgdeDossierMetadata.dates = dataMetadata.dates;
									modal.close();
		                    	}else{
		                    		modal.alerts.push({ msg: $filter('translate')('global.documents.EMGDE.errorUpdateEMGDE') });
		                    	}
		                    }).catch(function(error) {
		                        modal.alerts.push({ msg: $filter('translate')('global.documents.EMGDE.errorUpdateEMGDE') });
		                	});
	                    }).catch(function (data) {
	                    	//Empty
	                    });  
					}      	
					
				}
				AnnexaFormlyFactory.showModal("modalAddModifyDate", modal, saveDateConfirm, false);
			}
	
			vm.deleteLanguage = function(language){
				if(vm.emgdeDossierMetadata && vm.emgdeDossierMetadata.languages){
					DialogsFactory.confirm("global.documents.EMGDE.updateEMGDE", "global.documents.EMGDE.deleteLanguageConfirm").then(function (dataAux) {
						TramNewFactory.deleteMetadataObj(language, vm.emgdeDossierMetadata.id, 'emgdeDossierMetadataLanguage').then(function(dataMetadata) {
							var index = $linq(vm.emgdeDossierMetadata.languages).indexOf("x => x.id == "+language);
							if(index >= 0){
								vm.emgdeDossierMetadata.languages.splice(index, 1);
							}
	                    }).catch(function(error) {
	                        DialogsFactory.error($filter('translate')('global.documents.EMGDE.errorDeleteEMGDE'));
	                	});
						
					}).catch(function (data) {
                    	//Empty
                    });  						
				}
			}
			
			vm.addLanguage = function(language){
                var fields = [];
            	var data = {row: true,colClass: ' col-sm-12',labelClass: 'label-strong'};
                var modal = angular.copy(globalModals.emgdeModal);
                modal.annexaFormly = new AnnexaFormly();
                modal.annexaFormly.addGroup('modal_body','modal-body p-lg',fields);
                modal.annexaFormly.model = {};
                modal.annexaFormly.model.modal_body = {};
				if(language){
					modal.annexaFormly.model.modal_body = angular.copy(language);
				}
                modal.annexaFormly.options = {};
                modal.annexaFormly.options.formState = {readOnly: false};
                modal.alerts = [];
                var field = modal.annexaFormly.createField('language','annexaInputRow','col-sm-12',new AnnexaFormlyFieldTemplateOptions('text','global.documents.EMGDE.language',true,true,undefined,255),data);
                fields.push(field);
				var saveLanguageConfirm = function(){
					var model = modal.annexaFormly.model.modal_body;
					if(model){
	                	DialogsFactory.confirm("global.documents.EMGDE.updateEMGDE", "global.documents.EMGDE.saveLanguageConfirm").then(function (dataAux) {
							TramNewFactory.updateMetadataObj(model, vm.emgdeDossierMetadata.id, 'emgdeDossierMetadataLanguage').then(function(dataMetadata) {
		                    	if(dataMetadata && dataMetadata.languages){
		                    		vm.emgdeDossierMetadata.languages = dataMetadata.languages;
									modal.close();
		                    	}else{
		                    		modal.alerts.push({ msg: $filter('translate')('global.documents.EMGDE.errorUpdateEMGDE') });
		                    	}
		                    }).catch(function(error) {
		                        modal.alerts.push({ msg: $filter('translate')('global.documents.EMGDE.errorUpdateEMGDE') });
		                	});
	                    }).catch(function (data) {
	                    	//Empty
	                    });  
					}      	
					
				}
				AnnexaFormlyFactory.showModal("modalAddModifyLanguage", modal, saveLanguageConfirm, false);
			}
			
			vm.deleteSupport = function(locationSupport){
				if(vm.emgdeDossierMetadata && vm.emgdeDossierMetadata.locationSupports){
					DialogsFactory.confirm("global.documents.EMGDE.updateEMGDE", "global.documents.EMGDE.deleteSupportConfirm").then(function (dataAux) {
						TramNewFactory.deleteMetadataObj(locationSupport, vm.emgdeDossierMetadata.id, 'emgdeDossierMetadataSupport').then(function(dataMetadata) {
							var index = $linq(vm.emgdeDossierMetadata.locationSupports).indexOf("x => x.id == "+locationSupport);
							if(index >= 0){
								vm.emgdeDossierMetadata.locationSupports.splice(index, 1);
							}
	                    }).catch(function(error) {
	                        DialogsFactory.error($filter('translate')('global.documents.EMGDE.errorDeleteEMGDE'));
	                	});
					}).catch(function (data) {
                    	//Empty
                    });  						
				}
			}
			
			vm.addSupport = function(locationSupport){
				var fields = [];
            	var data = {row: true,colClass: ' col-sm-12',labelClass: 'label-strong'};
                var modal = angular.copy(globalModals.emgdeModal);
                modal.annexaFormly = new AnnexaFormly();
                modal.annexaFormly.addGroup('modal_body','modal-body p-lg',fields);
                modal.annexaFormly.model = {};
                modal.annexaFormly.model.modal_body = {};
				if(locationSupport){
					modal.annexaFormly.model.modal_body = angular.copy(locationSupport);
					if(modal.annexaFormly.model.modal_body && modal.annexaFormly.model.modal_body.support && modal.annexaFormly.model.modal_body.support.id){
						modal.annexaFormly.model.modal_body.support = modal.annexaFormly.model.modal_body.support.id;
					}
				}
                modal.annexaFormly.options = {};
                modal.annexaFormly.options.formState = {readOnly: false};
                modal.alerts = [];
				var field = modal.annexaFormly.createField('support','annexaSelectRow','col-sm-12',new AnnexaFormlyFieldSelectTemplateOptions('global.documents.EMGDE.support','id',vm.languageColumn,GlobalDataFactory.emgdeListsSupports,true),data);
                fields.push(field);
				var saveSupportConfirm = function(){
					var model = modal.annexaFormly.model.modal_body;
					if(model){
						DialogsFactory.confirm("global.documents.EMGDE.updateEMGDE", "global.documents.EMGDE.saveSupportConfirm").then(function (dataAux) {
							model = angular.copy(model);
							if(model.support){
								model.support = {id: model.support};
							}
	                		TramNewFactory.updateMetadataObj(model, vm.emgdeDossierMetadata.id, 'emgdeDossierMetadataSupport').then(function(dataMetadata) {
		                    	if(dataMetadata && dataMetadata.locationSupports){
		                    		vm.emgdeDossierMetadata.locationSupports = dataMetadata.locationSupports;
									modal.close();
		                    	}else{
		                    		modal.alerts.push({ msg: $filter('translate')('global.documents.EMGDE.errorUpdateEMGDE') });
		                    	}
		                    }).catch(function(error) {
		                        modal.alerts.push({ msg: $filter('translate')('global.documents.EMGDE.errorUpdateEMGDE') });
		                	});
	                    }).catch(function (data) {
	                    	//Empty
	                    });  
					}      	
					
				}
				AnnexaFormlyFactory.showModal("modalAddModifyLanguage", modal, saveSupportConfirm, false);
			}
			
			vm.deleteLocalization = function(locationLocalization){
				if(vm.emgdeDossierMetadata && vm.emgdeDossierMetadata.locationLocalizations){
					DialogsFactory.confirm("global.documents.EMGDE.updateEMGDE", "global.documents.EMGDE.deleteLocalizationConfirm").then(function (dataAux) {
						TramNewFactory.deleteMetadataObj(locationLocalization, vm.emgdeDossierMetadata.id, 'emgdeDossierMetadataLocalization').then(function(dataMetadata) {
							var index = $linq(vm.emgdeDossierMetadata.locationLocalizations).indexOf("x => x.id == "+locationLocalization);
							if(index >= 0){
								vm.emgdeDossierMetadata.locationLocalizations.splice(index, 1);
							}
	                    }).catch(function(error) {
	                        DialogsFactory.error($filter('translate')('global.documents.EMGDE.errorDeleteEMGDE'));
	                	});
					}).catch(function (data) {
                    	//Empty
                    });  						
				}
			}

			vm.addLocalization = function(locationLocalization){
				var fields = [];
            	var data = {row: true,colClass: ' col-sm-12',labelClass: 'label-strong'};
                var modal = angular.copy(globalModals.emgdeModal);
                modal.annexaFormly = new AnnexaFormly();
                modal.annexaFormly.addGroup('modal_body','modal-body p-lg',fields);
                modal.annexaFormly.model = {};
                modal.annexaFormly.model.modal_body = {};
				if(locationLocalization){
					modal.annexaFormly.model.modal_body = angular.copy(locationLocalization);
				}
                modal.annexaFormly.options = {};
                modal.annexaFormly.options.formState = {readOnly: false};
                modal.alerts = [];
                var field = modal.annexaFormly.createField('localization','annexaInputRow','col-sm-12',new AnnexaFormlyFieldTemplateOptions('text','global.documents.EMGDE.localization',false,true,undefined,1024),data);
                fields.push(field);
				var saveLocalizationConfirm = function(){
					var model = modal.annexaFormly.model.modal_body;
					if(model){
	                	DialogsFactory.confirm("global.documents.EMGDE.updateEMGDE", "global.documents.EMGDE.saveLocalizationConfirm").then(function (dataAux) {
	                		TramNewFactory.updateMetadataObj(model, vm.emgdeDossierMetadata.id, 'emgdeDossierMetadataLocalization').then(function(dataMetadata) {
		                    	if(dataMetadata && dataMetadata.locationLocalizations){
		                    		vm.emgdeDossierMetadata.locationLocalizations = dataMetadata.locationLocalizations;
									modal.close();
		                    	}else{
		                    		modal.alerts.push({ msg: $filter('translate')('global.documents.EMGDE.errorUpdateEMGDE') });
		                    	}
		                    }).catch(function(error) {
		                        modal.alerts.push({ msg: $filter('translate')('global.documents.EMGDE.errorUpdateEMGDE') });
		                	});
	                    }).catch(function (data) {
	                    	//Empty
	                    });  
					}      	
					
				}
				AnnexaFormlyFactory.showModal("modalAddModifyLanguage", modal, saveLocalizationConfirm, false);
			}
			
			vm.deleteSignature = function(signature){
				if(vm.emgdeDossierMetadata && vm.emgdeDossierMetadata.signatures){
					DialogsFactory.confirm("global.documents.EMGDE.updateEMGDE", "global.documents.EMGDE.deleteSignatureConfirm").then(function (dataAux) {
						TramNewFactory.deleteMetadataObj(signature, vm.emgdeDossierMetadata.id, 'emgdeDossierMetadataSignature').then(function(dataMetadata) {
							var index = $linq(vm.emgdeDossierMetadata.signatures).indexOf("x => x.id == "+signature);
							if(index >= 0){
								vm.emgdeDossierMetadata.signatures.splice(index, 1);
							}
	                    }).catch(function(error) {
	                        DialogsFactory.error($filter('translate')('global.documents.EMGDE.errorDeleteEMGDE'));
	                	});
					}).catch(function (data) {
                    	//Empty
                    });  						
				}
			}
			
			vm.addSignature = function(signature){
				var fields = [];
            	var data = {row: true,colClass: ' col-sm-12',labelClass: 'label-strong'};
                var modal = angular.copy(globalModals.emgdeModal);
                modal.annexaFormly = new AnnexaFormly();
                modal.annexaFormly.addGroup('modal_body','modal-body p-lg',fields);
                modal.annexaFormly.model = {};
                modal.annexaFormly.model.modal_body = {};
				if(signature){
					modal.annexaFormly.model.modal_body = angular.copy(signature);
				}
                modal.annexaFormly.options = {};
                modal.annexaFormly.options.formState = {readOnly: false};
                modal.alerts = [];
				var field = undefined;
				if(vm.metadata_signature_format){
					field = modal.annexaFormly.createField('formatSignature', 'annexaSelectRow', 'col-sm-12', new AnnexaFormlyFieldSelectTemplateOptions('global.documents.EMGDE.formatSignature','id','description',GlobalDataFactory.emgdeLists.signatureFormats,true),data);                
				}else{
					field = modal.annexaFormly.createField('formatSignatureAux', 'annexaLabelRow', 'col-sm-12',new AnnexaFormlyFieldLabelTemplateOptions('global.documents.EMGDE.formatSignature', ((signature)?vm.getLiteral('signatureFormats', signature.formatSignature):'')),data);
				}
				fields.push(field);
				if(vm.metadata_signature_profile){
					field = modal.annexaFormly.createField('profileSignature', 'annexaSelectRow', 'col-sm-12', new AnnexaFormlyFieldSelectTemplateOptions('global.documents.EMGDE.profileSignature','id','description',GlobalDataFactory.emgdeLists.signatureProfiles,true),data);                
				}else{
					field = modal.annexaFormly.createField('profileSignatureAux', 'annexaLabelRow', 'col-sm-12',new AnnexaFormlyFieldLabelTemplateOptions('global.documents.EMGDE.profileSignature', ((signature && signature.profileSignature)?signature.profileSignature:'')),data);
				}
                fields.push(field);
				var saveSignatureConfirm = function(){
					if(vm.metadata_signature_format || vm.metadata_signature_profile){
						var model = modal.annexaFormly.model.modal_body;
						if(model){
		                	DialogsFactory.confirm("global.documents.EMGDE.updateEMGDE", "global.documents.EMGDE.saveSignatureConfirm").then(function (dataAux) {
								TramNewFactory.updateMetadataObj(model, vm.emgdeDossierMetadata.id, 'emgdeDossierMetadataSignature').then(function(dataMetadata) {
			                    	if(dataMetadata && dataMetadata.signatures){
			                    		vm.emgdeDossierMetadata.signatures = dataMetadata.signatures;
										modal.close();
			                    	}else{
			                    		modal.alerts.push({ msg: $filter('translate')('global.documents.EMGDE.errorUpdateEMGDE') });
			                    	}
			                    }).catch(function(error) {
			                        modal.alerts.push({ msg: $filter('translate')('global.documents.EMGDE.errorUpdateEMGDE') });
			                	});
		                    }).catch(function (data) {
		                    	//Empty
		                    });  
						}						
					}else{
						modal.close();
					}
				}
				AnnexaFormlyFactory.showModal("modalAddModifyLanguage", modal, saveSignatureConfirm, false);
			}
			
			vm.deleteClassification = function(classification){
				if(vm.emgdeDossierMetadata && vm.emgdeDossierMetadata.classifications){
					DialogsFactory.confirm("global.documents.EMGDE.updateEMGDE", "global.documents.EMGDE.deleteClassificationConfirm").then(function (dataAux) {
						TramNewFactory.deleteMetadataObj(classification, vm.emgdeDossierMetadata.id, 'emgdeDossierMetadataClassification').then(function(dataMetadata) {
							var index = $linq(vm.emgdeDossierMetadata.classifications).indexOf("x => x.id == "+classification);
							if(index >= 0){
								vm.emgdeDossierMetadata.classifications.splice(index, 1);
							}
	                    }).catch(function(error) {
	                        DialogsFactory.error($filter('translate')('global.documents.EMGDE.errorDeleteEMGDE'));
	                	});
					}).catch(function (data) {
                    	//Empty
                    });  						
				}
			}
			
			vm.addClassification = function(classification){
				var fields = [];
            	var data = {row: true,colClass: ' col-sm-12',labelClass: 'label-strong'};
                var modal = angular.copy(globalModals.emgdeModal);
                modal.annexaFormly = new AnnexaFormly();
                modal.annexaFormly.addGroup('modal_body','modal-body p-lg',fields);
                modal.annexaFormly.model = {};
                modal.annexaFormly.model.modal_body = {};
				if(classification){
					modal.annexaFormly.model.modal_body = angular.copy(classification);
				}
                modal.annexaFormly.options = {};
                modal.annexaFormly.options.formState = {readOnly: false};
                modal.alerts = [];
				var field = undefined;
                if(vm.metadata_classification_code){
					field = modal.annexaFormly.createField('code','annexaInputRow','col-sm-12',new AnnexaFormlyFieldTemplateOptions('text','global.documents.EMGDE.classificationCode',true,true,undefined,255),data);
				}else{
					field = modal.annexaFormly.createField('codeAux', 'annexaLabelRow', 'col-sm-12',new AnnexaFormlyFieldLabelTemplateOptions('global.documents.EMGDE.classificationCode', ((classification && classification.code)?classification.code:'')),data);
				}
                fields.push(field);
				if(vm.isTransferedMode){
					if(vm.metadata_classification_name){
						field = modal.annexaFormly.createField('name','annexaInputRow','col-sm-12',new AnnexaFormlyFieldTemplateOptions('text','global.documents.EMGDE.classificationName',false,false,undefined,255),data);
					}else{
						field = modal.annexaFormly.createField('nameAux', 'annexaLabelRow', 'col-sm-12',new AnnexaFormlyFieldLabelTemplateOptions('global.documents.EMGDE.classificationName', ((classification && classification.name)?classification.name:'')),data);
					}
	                fields.push(field);
					if(vm.metadata_classification_type){
						field = modal.annexaFormly.createField('type', 'annexaSelectRow', 'col-sm-12', new AnnexaFormlyFieldSelectTemplateOptions('global.documents.EMGDE.classificationType','id','description',GlobalDataFactory.emgdeLists.classificationTypes,false),data);                
					}else{
						field = modal.annexaFormly.createField('typeAux', 'annexaLabelRow', 'col-sm-12',new AnnexaFormlyFieldLabelTemplateOptions('global.documents.EMGDE.classificationType',  ((classification && classification.type)?vm.getLiteral('classificationType', classification && classification.type):'')),data);
					}
	                fields.push(field);
				}
				var saveClassificationConfirm = function(){
					if(vm.metadata_classification_type || vm.metadata_classification_name || vm.metadata_classification_code){
						var model = modal.annexaFormly.model.modal_body;
						if(model){
		                	DialogsFactory.confirm("global.documents.EMGDE.updateEMGDE", "global.documents.EMGDE.saveClassificationConfirm").then(function (dataAux) {
								TramNewFactory.updateMetadataObj(model, vm.emgdeDossierMetadata.id, 'emgdeDossierMetadataClassification').then(function(dataMetadata) {
			                    	if(dataMetadata && dataMetadata.classifications){
			                    		vm.emgdeDossierMetadata.classifications = dataMetadata.classifications;
										modal.close();
			                    	}else{
			                    		modal.alerts.push({ msg: $filter('translate')('global.documents.EMGDE.errorUpdateEMGDE') });
			                    	}
			                    }).catch(function(error) {
			                        modal.alerts.push({ msg: $filter('translate')('global.documents.EMGDE.errorUpdateEMGDE') });
			                	});
		                    }).catch(function (data) {
		                    	//Empty
		                    });  
						}
					}else{
						modal.close();
					}      	
					
				}
				AnnexaFormlyFactory.showModal("modalAddModifyLanguage", modal, saveClassificationConfirm, false);
			}
	
			vm.deleteOrgan = function(organ){
				if(vm.emgdeDossierMetadata && vm.emgdeDossierMetadata.organs){
					DialogsFactory.confirm("global.documents.EMGDE.updateEMGDE", "global.documents.EMGDE.deleteOrganConfirm").then(function (dataAux) {
						TramNewFactory.deleteMetadataObj(organ, vm.emgdeDossierMetadata.id, 'emgdeDossierMetadataOrgan').then(function(dataMetadata) {
							var index = $linq(vm.emgdeDossierMetadata.organs).indexOf("x => x.id == "+organ);
							if(index >= 0){
								vm.emgdeDossierMetadata.organs.splice(index, 1);
							}
	                    }).catch(function(error) {
	                        DialogsFactory.error($filter('translate')('global.documents.EMGDE.errorDeleteEMGDE'));
	                	});
					}).catch(function (data) {
                    	//Empty
                    });  						
				}
			}
			
			vm.addOrgan = function(organ){
				var fields = [];
            	var data = {row: true,colClass: ' col-sm-12',labelClass: 'label-strong'};
                var modal = angular.copy(globalModals.emgdeModal);
                modal.annexaFormly = new AnnexaFormly();
                modal.annexaFormly.addGroup('modal_body','modal-body p-lg',fields);
                modal.annexaFormly.model = {};
                modal.annexaFormly.model.modal_body = {};
				if(organ){
					modal.annexaFormly.model.modal_body = angular.copy(organ);
				}
                modal.annexaFormly.options = {};
                modal.annexaFormly.options.formState = {readOnly: false};
                modal.alerts = [];
                var field = modal.annexaFormly.createField('organ','annexaInputRow','col-sm-12',new AnnexaFormlyFieldTemplateOptions('text','global.documents.EMGDE.organ',true,true,undefined,255),data);
                fields.push(field);
				var saveOrganConfirm = function(){
					var model = modal.annexaFormly.model.modal_body;
					if(model){
	                	DialogsFactory.confirm("global.documents.EMGDE.updateEMGDE", "global.documents.EMGDE.saveOrganConfirm").then(function (dataAux) {
							TramNewFactory.updateMetadataObj(model, vm.emgdeDossierMetadata.id, 'emgdeDossierMetadataOrgan').then(function(dataMetadata) {
		                    	if(dataMetadata && dataMetadata.organs){
		                    		vm.emgdeDossierMetadata.organs = dataMetadata.organs;
									modal.close();
		                    	}else{
		                    		modal.alerts.push({ msg: $filter('translate')('global.documents.EMGDE.errorUpdateEMGDE') });
		                    	}
		                    }).catch(function(error) {
		                        modal.alerts.push({ msg: $filter('translate')('global.documents.EMGDE.errorUpdateEMGDE') });
		                	});
	                    }).catch(function (data) {
	                    	//Empty
	                    });  
					}      	
					
				}
				AnnexaFormlyFactory.showModal("modalAddModifyLanguage", modal, saveOrganConfirm, false);			
			}
			
			vm.deleteSecurity = function(security){
				if(vm.emgdeDossierMetadata && vm.emgdeDossierMetadata.securities){
					DialogsFactory.confirm("global.documents.EMGDE.updateEMGDE", "global.documents.EMGDE.deleteSecurityConfirm").then(function (dataAux) {
						TramNewFactory.deleteMetadataObj(security, vm.emgdeDossierMetadata.id, 'emgdeDossierMetadataSecurity').then(function(dataMetadata) {
							var index = $linq(vm.emgdeDossierMetadata.securities).indexOf("x => x.id == "+security);
							if(index >= 0){
								vm.emgdeDossierMetadata.securities.splice(index, 1);
							}
	                    }).catch(function(error) {
	                        DialogsFactory.error($filter('translate')('global.documents.EMGDE.errorDeleteEMGDE'));
	                	});
					}).catch(function (data) {
                    	//Empty
                    });  						
				}
			}
			
			vm.addSecurity = function(security){
				var fields = [];
            	var data = {row: true,colClass: ' col-sm-12',labelClass: 'label-strong'};
                var modal = angular.copy(globalModals.emgdeModal);
                modal.annexaFormly = new AnnexaFormly();
                modal.annexaFormly.addGroup('modal_body','modal-body p-lg',fields);
                modal.annexaFormly.model = {};
                modal.annexaFormly.model.modal_body = {};
				if(security){
					modal.annexaFormly.model.modal_body = angular.copy(security);
				}
                modal.annexaFormly.options = {};
                modal.annexaFormly.options.formState = {readOnly: false};
                modal.alerts = [];
                var field = modal.annexaFormly.createField('level', 'annexaSelectRow', 'col-sm-12', new AnnexaFormlyFieldSelectTemplateOptions('global.documents.EMGDE.securityLevel','id','description',GlobalDataFactory.emgdeLists.securityLevels,true),data);
				fields.push(field);
				var saveSecuritiesConfirm = function(){
					var model = modal.annexaFormly.model.modal_body;
					if(model){
	                	DialogsFactory.confirm("global.documents.EMGDE.updateEMGDE", "global.documents.EMGDE.saveSecurityConfirm").then(function (dataAux) {
							TramNewFactory.updateMetadataObj(model, vm.emgdeDossierMetadata.id, 'emgdeDossierMetadataSecurity').then(function(dataMetadata) {
		                    	if(dataMetadata && dataMetadata.securities){
		                    		vm.emgdeDossierMetadata.securities = dataMetadata.securities;
									modal.close();
		                    	}else{
		                    		modal.alerts.push({ msg: $filter('translate')('global.documents.EMGDE.errorUpdateEMGDE') });
		                    	}
		                    }).catch(function(error) {
		                        modal.alerts.push({ msg: $filter('translate')('global.documents.EMGDE.errorUpdateEMGDE') });
		                	});
	                    }).catch(function (data) {
	                    	//Empty
	                    });  
					}      	
					
				}
				AnnexaFormlyFactory.showModal("modalAddModifyLanguage", modal, saveSecuritiesConfirm, false);
			}
			
			vm.deleteLimitCause = function(limitCause){
				if(vm.emgdeDossierMetadata && vm.emgdeDossierMetadata.limitCauses){
					DialogsFactory.confirm("global.documents.EMGDE.updateEMGDE", "global.documents.EMGDE.deleteLimitCauseConfirm").then(function (dataAux) {
						TramNewFactory.deleteMetadataObj(limitCause, vm.emgdeDossierMetadata.id, 'emgdeDossierMetadataLimitCause').then(function(dataMetadata) {
							var index = $linq(vm.emgdeDossierMetadata.limitCauses).indexOf("x => x.id == "+limitCause);
							if(index >= 0){
								vm.emgdeDossierMetadata.limitCauses.splice(index, 1);
							}
	                    }).catch(function(error) {
	                        DialogsFactory.error($filter('translate')('global.documents.EMGDE.errorDeleteEMGDE'));
	                	});
					}).catch(function (data) {
                    	//Empty
                    });  						
				}
			}
			
			vm.addLimitCause = function(limitCause){
				var fields = [];
            	var data = {row: true,colClass: ' col-sm-12',labelClass: 'label-strong'};
                var modal = angular.copy(globalModals.emgdeModal);
                modal.annexaFormly = new AnnexaFormly();
                modal.annexaFormly.addGroup('modal_body','modal-body p-lg',fields);
                modal.annexaFormly.model = {};
                modal.annexaFormly.model.modal_body = {};
				if(limitCause){
					modal.annexaFormly.model.modal_body = angular.copy(limitCause);
				}
                modal.annexaFormly.options = {};
                modal.annexaFormly.options.formState = {readOnly: false};
                modal.alerts = [];
                var field = modal.annexaFormly.createField('cause', 'annexaSelectRow', 'col-sm-12', new AnnexaFormlyFieldSelectTemplateOptions('global.documents.EMGDE.rightAccessCode','id','description',GlobalDataFactory.emgdeLists.limitCauses,true),data);
				fields.push(field);
				var saveLimitCausesConfirm = function(){
					var model = modal.annexaFormly.model.modal_body;
					if(model){
	                	DialogsFactory.confirm("global.documents.EMGDE.updateEMGDE", "global.documents.EMGDE.saveLimitCauseConfirm").then(function (dataAux) {
							TramNewFactory.updateMetadataObj(model, vm.emgdeDossierMetadata.id, 'emgdeDossierMetadataLimitCause').then(function(dataMetadata) {
		                    	if(dataMetadata && dataMetadata.limitCauses){
		                    		vm.emgdeDossierMetadata.limitCauses = dataMetadata.limitCauses;
									modal.close();
		                    	}else{
		                    		modal.alerts.push({ msg: $filter('translate')('global.documents.EMGDE.errorUpdateEMGDE') });
		                    	}
		                    }).catch(function(error) {
		                        modal.alerts.push({ msg: $filter('translate')('global.documents.EMGDE.errorUpdateEMGDE') });
		                	});
	                    }).catch(function (data) {
	                    	//Empty
	                    });  
					}      	
					
				}
				AnnexaFormlyFactory.showModal("modalAddModifyLanguage", modal, saveLimitCausesConfirm, false);
			}
			
			vm.deleteLegalLimitCause = function(legalLimitCause){
				if(vm.emgdeDossierMetadata && vm.emgdeDossierMetadata.legalLimitCauses){
					DialogsFactory.confirm("global.documents.EMGDE.updateEMGDE", "global.documents.EMGDE.deleteLegalLimitCauseConfirm").then(function (dataAux) {
						TramNewFactory.deleteMetadataObj(legalLimitCause, vm.emgdeDossierMetadata.id, 'emgdeDossierMetadataLegalLimitCause').then(function(dataMetadata) {
							var index = $linq(vm.emgdeDossierMetadata.legalLimitCauses).indexOf("x => x.id == "+legalLimitCause);
							if(index >= 0){
								vm.emgdeDossierMetadata.legalLimitCauses.splice(index, 1);
							}
	                    }).catch(function(error) {
	                        DialogsFactory.error($filter('translate')('global.documents.EMGDE.errorDeleteEMGDE'));
	                	});
					}).catch(function (data) {
                    	//Empty
                    });  						
				}
			}
			
			vm.addLegalLimitCause = function(legalLimitCause){
				var fields = [];
            	var data = {row: true,colClass: ' col-sm-12',labelClass: 'label-strong'};
                var modal = angular.copy(globalModals.emgdeModal);
                modal.annexaFormly = new AnnexaFormly();
                modal.annexaFormly.addGroup('modal_body','modal-body p-lg',fields);
                modal.annexaFormly.model = {};
                modal.annexaFormly.model.modal_body = {};
				if(legalLimitCause){
					modal.annexaFormly.model.modal_body = angular.copy(legalLimitCause);
				}
                modal.annexaFormly.options = {};
                modal.annexaFormly.options.formState = {readOnly: false};
                modal.alerts = [];
                var field = modal.annexaFormly.createField('cause','annexaInputRow','col-sm-12',new AnnexaFormlyFieldTemplateOptions('text','global.documents.EMGDE.rightAccessLegalMotive',true,false),data);
				fields.push(field);
				var saveLegalLimitCausesConfirm = function(){
					var model = modal.annexaFormly.model.modal_body;
					if(model){
	                	DialogsFactory.confirm("global.documents.EMGDE.updateEMGDE", "global.documents.EMGDE.saveLegalLimitCauseConfirm").then(function (dataAux) {
							TramNewFactory.updateMetadataObj(model, vm.emgdeDossierMetadata.id, 'emgdeDossierMetadataLegalLimitCause').then(function(dataMetadata) {
		                    	if(dataMetadata && dataMetadata.legalLimitCauses){
		                    		vm.emgdeDossierMetadata.legalLimitCauses = dataMetadata.legalLimitCauses;
									modal.close();
		                    	}else{
		                    		modal.alerts.push({ msg: $filter('translate')('global.documents.EMGDE.errorUpdateEMGDE') });
		                    	}
		                    }).catch(function(error) {
		                        modal.alerts.push({ msg: $filter('translate')('global.documents.EMGDE.errorUpdateEMGDE') });
		                	});
	                    }).catch(function (data) {
	                    	//Empty
	                    });  
					}      	
					
				}
				AnnexaFormlyFactory.showModal("modalAddModifyLanguage", modal, saveLegalLimitCausesConfirm, false);
			}

			vm.deleteTransfer = function(transfer){
				if(vm.emgdeDossierMetadata && vm.emgdeDossierMetadata.transfers){
					DialogsFactory.confirm("global.documents.EMGDE.updateEMGDE", "global.documents.EMGDE.deleteTransferConfirm").then(function (dataAux) {
						TramNewFactory.deleteMetadataObj(transfer, vm.emgdeDossierMetadata.id, 'emgdeDossierMetadataTransfer').then(function(dataMetadata) {
	                    	var index = $linq(vm.emgdeDossierMetadata.transfers).indexOf("x => x.id == "+transfer);
							if(index >= 0){
								vm.emgdeDossierMetadata.transfers.splice(index, 1);
							}
	                    }).catch(function(error) {
	                        DialogsFactory.error($filter('translate')('global.documents.EMGDE.errorDeleteEMGDE'));
	                	});
					}).catch(function (data) {
                    	//Empty
                    });  						
				}
			}
			
			vm.addTransfer = function(transfer){
				var fields = [];
            	var data = {row: true,colClass: ' col-sm-12',labelClass: 'label-strong'};
                var modal = angular.copy(globalModals.emgdeModal);
                modal.annexaFormly = new AnnexaFormly();
                modal.annexaFormly.addGroup('modal_body','modal-body p-lg',fields);
                modal.annexaFormly.model = {};
                modal.annexaFormly.model.modal_body = {};
				if(transfer){
					modal.annexaFormly.model.modal_body = angular.copy(transfer);
				}
                modal.annexaFormly.options = {};
                modal.annexaFormly.options.formState = {readOnly: false};
                modal.alerts = [];
				var field = undefined;
				if(vm.metadata_transfer_archive){
					field = modal.annexaFormly.createField('archive', 'annexaSelectRow', 'col-sm-12', new AnnexaFormlyFieldSelectTemplateOptions('global.documents.EMGDE.archive','id','description',GlobalDataFactory.emgdeLists.archiveFases,true),data);                
				}else{
					field = modal.annexaFormly.createField('archiveAux', 'annexaLabelRow', 'col-sm-12',new AnnexaFormlyFieldLabelTemplateOptions('global.documents.EMGDE.archive', ((transfer)?vm.getLiteral('archive', transfer.archive):'')),data);
				}
				fields.push(field);
				if(vm.metadata_transfer_terms){
					field = modal.annexaFormly.createField('terms','annexaInputRow','col-sm-12',new AnnexaFormlyFieldTemplateOptions('number','global.documents.EMGDE.transferDeadline',true,false),data);                
				}else{
					field = modal.annexaFormly.createField('termsAux', 'annexaLabelRow', 'col-sm-12',new AnnexaFormlyFieldLabelTemplateOptions('global.documents.EMGDE.transferDeadline', ((transfer && transfer.number)?transfer.number:'')),data);
				}
                fields.push(field);
				var saveTransferConfirm = function(){
					if(vm.metadata_transfer_archive || vm.metadata_transfer_terms){
						var model = modal.annexaFormly.model.modal_body;
						if(model){
							DialogsFactory.confirm("global.documents.EMGDE.updateEMGDE", "global.documents.EMGDE.saveTransferConfirm").then(function (dataAux) {
								TramNewFactory.updateMetadataObj(model, vm.emgdeDossierMetadata.id, 'emgdeDossierMetadataTransfer').then(function(dataMetadata) {
			                    	if(dataMetadata && dataMetadata.transfers){
			                    		vm.emgdeDossierMetadata.transfers = dataMetadata.transfers;
										modal.close();
			                    	}else{
			                    		modal.alerts.push({ msg: $filter('translate')('global.documents.EMGDE.errorUpdateEMGDE') });
			                    	}
			                    }).catch(function(error) {
			                        modal.alerts.push({ msg: $filter('translate')('global.documents.EMGDE.errorUpdateEMGDE') });
			                	});
					        }).catch(function (data) {
		                    	//Empty
		                    });  
						}						
					}else{
						modal.close();
					}
				}
				AnnexaFormlyFactory.showModal("modalAddModifyLanguage", modal, saveTransferConfirm, false);
			}
			
			vm.deleteOpinion = function(opinion){
				if(vm.emgdeDossierMetadata && vm.emgdeDossierMetadata.opinions){
					DialogsFactory.confirm("global.documents.EMGDE.updateEMGDE", "global.documents.EMGDE.deleteOpinionConfirm").then(function (dataAux) {
						TramNewFactory.deleteMetadataObj(opinion, vm.emgdeDossierMetadata.id, 'emgdeDossierMetadataOpinion').then(function(dataMetadata) {
	                    	var index = $linq(vm.emgdeDossierMetadata.opinions).indexOf("x => x.id == "+opinion);
							if(index >= 0){
								vm.emgdeDossierMetadata.opinions.splice(index, 1);
							}
	                    }).catch(function(error) {
	                        DialogsFactory.error($filter('translate')('global.documents.EMGDE.errorDeleteEMGDE'));
	                	});
					}).catch(function (data) {
                    	//Empty
                    });  						
				}
			}
			
			vm.addOpinion = function(opinion){
				var fields = [];
            	var data = {row: true,colClass: ' col-sm-12',labelClass: 'label-strong'};
				var modal = angular.copy(globalModals.emgdeModal);
                modal.annexaFormly = new AnnexaFormly();
                modal.annexaFormly.addGroup('modal_body','modal-body p-lg',fields);
                modal.annexaFormly.model = {};
                modal.annexaFormly.model.modal_body = {};
				if(opinion){
					modal.annexaFormly.model.modal_body = angular.copy(opinion);
				}
                modal.annexaFormly.options = {};
                modal.annexaFormly.options.formState = {readOnly: false};
                modal.alerts = [];
				var field = undefined;
				if(!opinion){
					field = modal.annexaFormly.createField('type', 'annexaSelectRow', 'col-sm-12', new AnnexaFormlyFieldSelectTemplateOptions('global.documents.EMGDE.opinionType','id','description',GlobalDataFactory.emgdeLists.opinionTypes,true),data);                
					fields.push(field);
					field = modal.annexaFormly.createField('action','annexaInputRow','col-sm-12',new AnnexaFormlyFieldTemplateOptions('text','global.documents.EMGDE.dictatedAction',true,false,undefined,255),data);
					field.hideExpression = function($viewValue, $modelValue, scope) {
						if(scope.model.type == 'PD'){
							scope.model.action = undefined;
							return true;	
						}else{
                    		return false;
						}
                    }
					fields.push(field);
				}
				var hideTermsFunction = function($viewValue, $modelValue, scope) {
					if(scope.model.type){
						if(scope.model.type == 'PD'){
							scope.model.terms = undefined;
							return true;	
						}else{
                    		return false;
						}
					}else if(opinion){
						if(!vm.showMetadataOpinionAction(opinion)){
							scope.model.terms = undefined;
							return true;	
						}else{
                    		return false;
						}
					}else{
						return false;
					}
                }    
				if(vm.metadata_opinion_terms){
					field = modal.annexaFormly.createField('terms','annexaInputRow','col-sm-12',new AnnexaFormlyFieldTemplateOptions('number','global.documents.EMGDE.dictatedActionDeadline',true,false),data);
					field.hideExpression = hideTermsFunction;          
				}else{
					field = modal.annexaFormly.createField('termsAux', 'annexaLabelRow', 'col-sm-12',new AnnexaFormlyFieldLabelTemplateOptions('global.documents.EMGDE.dictatedActionDeadline', ((transfer && transfer.number)?transfer.number:'')),data);
					field.hideExpression = hideTermsFunction;
				}
                fields.push(field);
				var saveOpinionConfirm = function(){
					if(vm.metadata_opinion_terms){
						var model = modal.annexaFormly.model.modal_body;
						var show = true;
						if(opinion && !vm.showMetadataOpinionAction(opinion)){
							show = false;	
						}
						if(model && show){
							if(!opinion){
								if(model.type){
									model.types = [];
									model.types.push({type:model.type});
								}
								if(model.action){
									model.actions = [];
									model.actions.push({action:model.action});
								}
							}
							DialogsFactory.confirm("global.documents.EMGDE.updateEMGDE", "global.documents.EMGDE.saveOpinionConfirm").then(function (dataAux) {
								TramNewFactory.updateMetadataObj(model, vm.emgdeDossierMetadata.id, 'emgdeDossierMetadataOpinion').then(function(dataMetadata) {
			                    	if(dataMetadata && dataMetadata.opinions){
			                    		vm.emgdeDossierMetadata.opinions = dataMetadata.opinions;
										modal.close();
			                    	}else{
			                    		modal.alerts.push({ msg: $filter('translate')('global.documents.EMGDE.errorUpdateEMGDE') });
			                    	}
			                    }).catch(function(error) {
			                        modal.alerts.push({ msg: $filter('translate')('global.documents.EMGDE.errorUpdateEMGDE') });
			                	});
					        }).catch(function (data) {
		                    	//Empty
		                    });  
						}else{
							modal.close();
						}		
					}else{
						modal.close();
					}
				}
				AnnexaFormlyFactory.showModal("modalAddModifyLanguage", modal, saveOpinionConfirm, false);
			}
			
			vm.deleteOpinionType = function(opinion, type){
				if(opinion && opinion.id && opinion.types){
					DialogsFactory.confirm("global.documents.EMGDE.updateEMGDE", "global.documents.EMGDE.deleteOpinionTypeConfirm").then(function (dataAux) {
						TramNewFactory.deleteMetadataObj(type, opinion.id, 'emgdeDossierMetadataOpinionType').then(function(dataMetadata) {
	                    	var index = $linq(opinion.types).indexOf("x => x.id == "+type);
							if(index >= 0){
								opinion.types.splice(index, 1);
							}
	                    }).catch(function(error) {
	                        DialogsFactory.error($filter('translate')('global.documents.EMGDE.errorDeleteEMGDE'));
	                	});
					}).catch(function (data) {
                    	//Empty
                    });  						
				}
			}
			
			vm.addOpinionType = function(opinion, type){
				var fields = [];
            	var data = {row: true,colClass: ' col-sm-12',labelClass: 'label-strong'};
                var modal = angular.copy(globalModals.emgdeModal);
                modal.annexaFormly = new AnnexaFormly();
                modal.annexaFormly.addGroup('modal_body','modal-body p-lg',fields);
                modal.annexaFormly.model = {};
                modal.annexaFormly.model.modal_body = {};
				if(type){
					modal.annexaFormly.model.modal_body = angular.copy(type);
				}
                modal.annexaFormly.options = {};
                modal.annexaFormly.options.formState = {readOnly: false};
                modal.alerts = [];
				var field = undefined;
				field = modal.annexaFormly.createField('type', 'annexaSelectRow', 'col-sm-12', new AnnexaFormlyFieldSelectTemplateOptions('global.documents.EMGDE.opinionType','id','description',GlobalDataFactory.emgdeLists.opinionTypes,true),data);                
                fields.push(field);
				var saveOpinionTypeConfirm = function(){
					var model = modal.annexaFormly.model.modal_body;
					if(model){
						DialogsFactory.confirm("global.documents.EMGDE.updateEMGDE", "global.documents.EMGDE.saveOpinionTypeConfirm").then(function (dataAux) {
							TramNewFactory.updateMetadataObj(model, opinion.id, 'emgdeDossierMetadataOpinionType').then(function(dataMetadata) {
		                    	if(dataMetadata && dataMetadata.types){
		                    		opinion.types = dataMetadata.types;
									if(opinion && opinion.types && opinion.types.length == 1 && opinion.types[0].type == 'PD'){
										if(opinion.actions){
											opinion.actions.length = 0;
										}else{
											opinion.actions = [];	
										}
										opinion.terms = undefined;
									}
									modal.close();
		                    	}else{
		                    		modal.alerts.push({ msg: $filter('translate')('global.documents.EMGDE.errorUpdateEMGDE') });
		                    	}
		                    }).catch(function(error) {
		                        modal.alerts.push({ msg: $filter('translate')('global.documents.EMGDE.errorUpdateEMGDE') });
		                	});
				        }).catch(function (data) {
	                    	//Empty
	                    });  
					}						
				}
				AnnexaFormlyFactory.showModal("modalAddModifyLanguage", modal, saveOpinionTypeConfirm, false);
			}
			
			vm.deleteOpinionAction = function(opinion, action){
				if(opinion && opinion.id && opinion.actions){
					DialogsFactory.confirm("global.documents.EMGDE.updateEMGDE", "global.documents.EMGDE.deleteOpinionActionConfirm").then(function (dataAux) {
						TramNewFactory.deleteMetadataObj(action, opinion.id, 'emgdeDossierMetadataOpinionAction').then(function(dataMetadata) {
	                    	var index = $linq(opinion.actions).indexOf("x => x.id == "+action);
							if(index >= 0){
								opinion.actions.splice(index, 1);
							}
	                    }).catch(function(error) {
	                        DialogsFactory.error($filter('translate')('global.documents.EMGDE.errorDeleteEMGDE'));
	                	});
					}).catch(function (data) {
                    	//Empty
                    });  						
				}
			}
			
			vm.addOpinionAction = function(opinion, action){
				var fields = [];
            	var data = {row: true,colClass: ' col-sm-12',labelClass: 'label-strong'};
                var modal = angular.copy(globalModals.emgdeModal);
                modal.annexaFormly = new AnnexaFormly();
                modal.annexaFormly.addGroup('modal_body','modal-body p-lg',fields);
                modal.annexaFormly.model = {};
                modal.annexaFormly.model.modal_body = {};
				if(action){
					modal.annexaFormly.model.modal_body = angular.copy(action);
				}
                modal.annexaFormly.options = {};
                modal.annexaFormly.options.formState = {readOnly: false};
                modal.alerts = [];
				var field = undefined;
				field = modal.annexaFormly.createField('action','annexaInputRow','col-sm-12',new AnnexaFormlyFieldTemplateOptions('text','global.documents.EMGDE.dictatedAction',true,false,undefined,255),data);
                fields.push(field);
				var saveOpinionActionConfirm = function(){
					var model = modal.annexaFormly.model.modal_body;
					if(model){
						DialogsFactory.confirm("global.documents.EMGDE.updateEMGDE", "global.documents.EMGDE.saveOpinionActionConfirm").then(function (dataAux) {
							TramNewFactory.updateMetadataObj(model, opinion.id, 'emgdeDossierMetadataOpinionAction').then(function(dataMetadata) {
		                    	if(dataMetadata && dataMetadata.actions){
		                    		opinion.actions = dataMetadata.actions;
									modal.close();
		                    	}else{
		                    		modal.alerts.push({ msg: $filter('translate')('global.documents.EMGDE.errorUpdateEMGDE') });
		                    	}
		                    }).catch(function(error) {
		                        modal.alerts.push({ msg: $filter('translate')('global.documents.EMGDE.errorUpdateEMGDE') });
		                	});
				        }).catch(function (data) {
	                    	//Empty
	                    });  
					}						
				}
				AnnexaFormlyFactory.showModal("modalAddModifyLanguage", modal, saveOpinionActionConfirm, false);
			}
			
			vm.deleteInterested = function(interested){
				if(vm.emgdeDossierMetadata && vm.emgdeDossierMetadata.interesteds){
					DialogsFactory.confirm("global.documents.EMGDE.updateEMGDE", "global.documents.EMGDE.deleteInterestedConfirm").then(function (dataAux) {
						TramNewFactory.deleteMetadataObj(interested, vm.emgdeDossierMetadata.id, 'emgdeDossierMetadataInterested').then(function(dataMetadata) {
							var index = $linq(vm.emgdeDossierMetadata.interesteds).indexOf("x => x.id == "+interested);
							if(index >= 0){
								vm.emgdeDossierMetadata.interesteds.splice(index, 1);
							}
	                    }).catch(function(error) {
	                        DialogsFactory.error($filter('translate')('global.documents.EMGDE.errorDeleteEMGDE'));
	                	});
						
					}).catch(function (data) {
                    	//Empty
                    });  						
				}
			}
		
			vm.addInterested = function(interested){
                var fields = [];
            	var data = {row: true,colClass: ' col-sm-12',labelClass: 'label-strong'};
                var modal = angular.copy(globalModals.emgdeModal);
                modal.annexaFormly = new AnnexaFormly();
                modal.annexaFormly.addGroup('modal_body','modal-body p-lg',fields);
                modal.annexaFormly.model = {};
                modal.annexaFormly.model.modal_body = {};
				if(interested){
					modal.annexaFormly.model.modal_body = angular.copy(interested);
				}
                modal.annexaFormly.options = {};
                modal.annexaFormly.options.formState = {readOnly: false};
                modal.alerts = [];
                var field = modal.annexaFormly.createField('interested','annexaInputRow','col-sm-12',new AnnexaFormlyFieldTemplateOptions('text','global.documents.EMGDE.interested',true,true,undefined,255),data);
                fields.push(field);
				var saveInterestedConfirm = function(){
					var model = modal.annexaFormly.model.modal_body;
					if(model){
	                	DialogsFactory.confirm("global.documents.EMGDE.updateEMGDE", "global.documents.EMGDE.saveInterestedConfirm").then(function (dataAux) {
							TramNewFactory.updateMetadataObj(model, vm.emgdeDossierMetadata.id, 'emgdeDossierMetadataInterested').then(function(dataMetadata) {
		                    	if(dataMetadata && dataMetadata.interesteds){
		                    		vm.emgdeDossierMetadata.interesteds = dataMetadata.interesteds;
									modal.close();
		                    	}else{
		                    		modal.alerts.push({ msg: $filter('translate')('global.documents.EMGDE.errorUpdateEMGDE') });
		                    	}
		                    }).catch(function(error) {
		                        modal.alerts.push({ msg: $filter('translate')('global.documents.EMGDE.errorUpdateEMGDE') });
		                	});
	                    }).catch(function (data) {
	                    	//Empty
	                    });  
					}      	
					
				}
				AnnexaFormlyFactory.showModal("modalAddModifyInterested", modal, saveInterestedConfirm, false);
			}
			
			vm.showMetadataOpinionAction = function(opinion){
				var show = true;
				if(opinion && opinion.types && opinion.types.length == 1 && opinion.types[0].type == 'PD'){
					show = false;
				}	
				return show;
			}
			
			vm.showRightAccessLibre = function(){
				var show = false;
				if(vm.emgdeDossierMetadata && vm.emgdeDossierMetadata.accesType == 'Libre'){
					show = true;
				}	
				return show;
			}
			
			vm.showDocumentIdentificationOrigin = function(){
				var show = false;
				if(vm.emgdeDossierMetadata && (vm.emgdeDossierMetadata.elaborationStatus == 'EE02' || vm.emgdeDossierMetadata.elaborationStatus == 'EE03' || vm.emgdeDossierMetadata.elaborationStatus == 'EE04')){
					show = true;
				}	
				return show;
			}
			
            this.$onInit = function () {
                vm.isDossierEniPresent = $rootScope.app.configuration.default_eni_values.show_dossier_eni;
				var emgdeUpdateConfiguration =  $rootScope.app.configuration.emgde_dossier_update_configuration;
				
				var getValueConfiguration = function(config, havePermission){
					var value = false;
					if(config && ((config == 'Lliure' || config == 'Restringida') && havePermission)){
						value = true;
					}
					return value;
				}
				if(emgdeUpdateConfiguration){
					var havePermission = false;
					if(AnnexaPermissionsFactory.havePermission('advanced_metadata_edit')) {
                    	havePermission = true;
                    }
					vm.metadata_entity_type = getValueConfiguration(emgdeUpdateConfiguration.metadata_entity_type, havePermission);
					vm.metadata_category = getValueConfiguration(emgdeUpdateConfiguration.metadata_category, havePermission);
					vm.metadata_identifier_sequence = getValueConfiguration(emgdeUpdateConfiguration.metadata_identifier_sequence, havePermission);
					vm.metadata_name_natural = getValueConfiguration(emgdeUpdateConfiguration.metadata_name_natural, havePermission);;
					vm.metadata_nti_version = getValueConfiguration(emgdeUpdateConfiguration.metadata_nti_version, havePermission);
					vm.metadata_dossier_status = getValueConfiguration(emgdeUpdateConfiguration.metadata_dossier_status, havePermission);
					vm.metadata_date_start_date_update = getValueConfiguration(((emgdeUpdateConfiguration.metadata_date_start_date)?emgdeUpdateConfiguration.metadata_date_start_date.update:undefined), havePermission);
					vm.metadata_date_start_date_delete = getValueConfiguration(((emgdeUpdateConfiguration.metadata_date_start_date)?emgdeUpdateConfiguration.metadata_date_start_date.delete:undefined), havePermission);
					vm.metadata_date_start_date_deleteFirst = getValueConfiguration(((emgdeUpdateConfiguration.metadata_date_start_date)?emgdeUpdateConfiguration.metadata_date_start_date.deleteFirst:undefined), havePermission);
					vm.metadata_date_start_date_create = getValueConfiguration(((emgdeUpdateConfiguration.metadata_date_start_date)?emgdeUpdateConfiguration.metadata_date_start_date.create:undefined), havePermission);
					vm.metadata_dates_start_date = getValueConfiguration(emgdeUpdateConfiguration.metadata_dates_start_date, havePermission);
					vm.metadata_dates_end_date = getValueConfiguration(emgdeUpdateConfiguration.metadata_dates_end_date, havePermission);
					vm.metadata_security_update = getValueConfiguration(((emgdeUpdateConfiguration.metadata_security)?emgdeUpdateConfiguration.metadata_security.update:undefined), havePermission);
					vm.metadata_security_delete = getValueConfiguration(((emgdeUpdateConfiguration.metadata_security)?emgdeUpdateConfiguration.metadata_security.delete:undefined), havePermission);
					vm.metadata_security_deleteFirst = getValueConfiguration(((emgdeUpdateConfiguration.metadata_security)?emgdeUpdateConfiguration.metadata_security.deleteFirst:undefined), havePermission);
					vm.metadata_security_create = getValueConfiguration(((emgdeUpdateConfiguration.metadata_security)?emgdeUpdateConfiguration.metadata_security.create:undefined), havePermission);
					vm.metadata_access_type = getValueConfiguration(emgdeUpdateConfiguration.metadata_access_type, havePermission);
					vm.metadata_limit_cause_update = getValueConfiguration(((emgdeUpdateConfiguration.metadata_limit_cause)?emgdeUpdateConfiguration.metadata_limit_cause.update:undefined), havePermission);
					vm.metadata_limit_cause_delete = getValueConfiguration(((emgdeUpdateConfiguration.metadata_limit_cause)?emgdeUpdateConfiguration.metadata_limit_cause.delete:undefined), havePermission);
					vm.metadata_limit_cause_deleteFirst = getValueConfiguration(((emgdeUpdateConfiguration.metadata_limit_cause)?emgdeUpdateConfiguration.metadata_limit_cause.deleteFirst:undefined), havePermission);
					vm.metadata_limit_cause_create = getValueConfiguration(((emgdeUpdateConfiguration.metadata_limit_cause)?emgdeUpdateConfiguration.metadata_limit_cause.create:undefined), havePermission);
					vm.metadata_legal_limit_cause_update = getValueConfiguration(((emgdeUpdateConfiguration.metadata_legal_limit_cause)?emgdeUpdateConfiguration.metadata_legal_limit_cause.update:undefined), havePermission);
					vm.metadata_legal_limit_cause_delete = getValueConfiguration(((emgdeUpdateConfiguration.metadata_legal_limit_cause)?emgdeUpdateConfiguration.metadata_legal_limit_cause.delete:undefined), havePermission);
					vm.metadata_legal_limit_cause_deleteFirst = getValueConfiguration(((emgdeUpdateConfiguration.metadata_legal_limit_cause)?emgdeUpdateConfiguration.metadata_legal_limit_cause.deleteFirst:undefined), havePermission);
					vm.metadata_legal_limit_cause_create = getValueConfiguration(((emgdeUpdateConfiguration.metadata_legal_limit_cause)?emgdeUpdateConfiguration.metadata_legal_limit_cause.create:undefined), havePermission);
					vm.metadata_reuse_condition = getValueConfiguration(emgdeUpdateConfiguration.metadata_reuse_condition, havePermission);
					vm.metadata_language_language_update = getValueConfiguration(((emgdeUpdateConfiguration.metadata_language_language)?emgdeUpdateConfiguration.metadata_language_language.update:undefined), havePermission);
					vm.metadata_language_language_delete = getValueConfiguration(((emgdeUpdateConfiguration.metadata_language_language)?emgdeUpdateConfiguration.metadata_language_language.delete:undefined), havePermission);
					vm.metadata_language_language_deleteFirst = getValueConfiguration(((emgdeUpdateConfiguration.metadata_language_language)?emgdeUpdateConfiguration.metadata_language_language.deleteFirst:undefined), havePermission);
					vm.metadata_language_language_create = getValueConfiguration(((emgdeUpdateConfiguration.metadata_language_language)?emgdeUpdateConfiguration.metadata_language_language.create:undefined), havePermission);
					vm.metadata_value_type = getValueConfiguration(emgdeUpdateConfiguration.metadata_value_type, havePermission);
					vm.metadata_terms = getValueConfiguration(emgdeUpdateConfiguration.metadata_terms, havePermission);
					vm.metadata_secundary_value = getValueConfiguration(emgdeUpdateConfiguration.metadata_secundary_value, havePermission);
					vm.metadata_opinion_update = getValueConfiguration(((emgdeUpdateConfiguration.metadata_opinion)?emgdeUpdateConfiguration.metadata_opinion.update:undefined), havePermission);
					vm.metadata_opinion_delete = getValueConfiguration(((emgdeUpdateConfiguration.metadata_opinion)?emgdeUpdateConfiguration.metadata_opinion.delete:undefined), havePermission);
					vm.metadata_opinion_deleteFirst = getValueConfiguration(((emgdeUpdateConfiguration.metadata_opinion)?emgdeUpdateConfiguration.metadata_opinion.deleteFirst:undefined), havePermission);
					vm.metadata_opinion_create = getValueConfiguration(((emgdeUpdateConfiguration.metadata_opinion)?emgdeUpdateConfiguration.metadata_opinion.create:undefined), havePermission);
					vm.metadata_opinion_type_update = getValueConfiguration(((emgdeUpdateConfiguration.metadata_opinion_type)?emgdeUpdateConfiguration.metadata_opinion_type.update:undefined), havePermission);
					vm.metadata_opinion_type_delete = getValueConfiguration(((emgdeUpdateConfiguration.metadata_opinion_type)?emgdeUpdateConfiguration.metadata_opinion_type.delete:undefined), havePermission);
					vm.metadata_opinion_type_deleteFirst = getValueConfiguration(((emgdeUpdateConfiguration.metadata_opinion_type)?emgdeUpdateConfiguration.metadata_opinion_type.deleteFirst:undefined), havePermission);
					vm.metadata_opinion_type_create = getValueConfiguration(((emgdeUpdateConfiguration.metadata_opinion_type)?emgdeUpdateConfiguration.metadata_opinion_type.create:undefined), havePermission);
					vm.metadata_opinion_action_update = getValueConfiguration(((emgdeUpdateConfiguration.metadata_opinion_action)?emgdeUpdateConfiguration.metadata_opinion_action.update:undefined), havePermission);
					vm.metadata_opinion_action_delete = getValueConfiguration(((emgdeUpdateConfiguration.metadata_opinion_action)?emgdeUpdateConfiguration.metadata_opinion_action.delete:undefined), havePermission);
					vm.metadata_opinion_action_deleteFirst = getValueConfiguration(((emgdeUpdateConfiguration.metadata_opinion_action)?emgdeUpdateConfiguration.metadata_opinion_action.deleteFirst:undefined), havePermission);
					vm.metadata_opinion_action_create = getValueConfiguration(((emgdeUpdateConfiguration.metadata_opinion_action)?emgdeUpdateConfiguration.metadata_opinion_action.create:undefined), havePermission);
					vm.metadata_opinion_terms = getValueConfiguration(emgdeUpdateConfiguration.metadata_opinion_terms, havePermission);
					vm.metadata_transfer_update = getValueConfiguration(((emgdeUpdateConfiguration.metadata_transfer)?emgdeUpdateConfiguration.metadata_transfer.update:undefined), havePermission);
					vm.metadata_transfer_delete = getValueConfiguration(((emgdeUpdateConfiguration.metadata_transfer)?emgdeUpdateConfiguration.metadata_transfer.delete:undefined), havePermission);
					vm.metadata_transfer_deleteFirst = getValueConfiguration(((emgdeUpdateConfiguration.metadata_transfer)?emgdeUpdateConfiguration.metadata_transfer.deleteFirst:undefined), havePermission);
					vm.metadata_transfer_create = getValueConfiguration(((emgdeUpdateConfiguration.metadata_transfer)?emgdeUpdateConfiguration.metadata_transfer.create:undefined), havePermission);
					vm.metadata_transfer_archive = getValueConfiguration(emgdeUpdateConfiguration.metadata_transfer_archive, havePermission);
					vm.metadata_transfer_terms = getValueConfiguration(emgdeUpdateConfiguration.metadata_transfer_terms, havePermission);
					vm.metadata_essential_document = getValueConfiguration(emgdeUpdateConfiguration.metadata_essential_document, havePermission);
					vm.metadata_location_support_update = getValueConfiguration(((emgdeUpdateConfiguration.metadata_location_support)?emgdeUpdateConfiguration.metadata_location_support.update:undefined), havePermission);
					vm.metadata_location_support_delete = getValueConfiguration(((emgdeUpdateConfiguration.metadata_location_support)?emgdeUpdateConfiguration.metadata_location_support.delete:undefined), havePermission);
					vm.metadata_location_support_deleteFirst = getValueConfiguration(((emgdeUpdateConfiguration.metadata_location_support)?emgdeUpdateConfiguration.metadata_location_support.deleteFirst:undefined), havePermission);
					vm.metadata_location_support_create = getValueConfiguration(((emgdeUpdateConfiguration.metadata_location_support)?emgdeUpdateConfiguration.metadata_location_support.create:undefined), havePermission);
					vm.metadata_location_localization_update = getValueConfiguration(((emgdeUpdateConfiguration.metadata_location_localization)?emgdeUpdateConfiguration.metadata_location_localization.update:undefined), havePermission);
					vm.metadata_location_localization_delete = getValueConfiguration(((emgdeUpdateConfiguration.metadata_location_localization)?emgdeUpdateConfiguration.metadata_location_localization.delete:undefined), havePermission);
					vm.metadata_location_localization_deleteFirst = getValueConfiguration(((emgdeUpdateConfiguration.metadata_location_localization)?emgdeUpdateConfiguration.metadata_location_localization.deleteFirst:undefined), havePermission);
					vm.metadata_location_localization_create = getValueConfiguration(((emgdeUpdateConfiguration.metadata_location_localization)?emgdeUpdateConfiguration.metadata_location_localization.create:undefined), havePermission);
					vm.metadata_signature_update = getValueConfiguration(((emgdeUpdateConfiguration.metadata_signature)?emgdeUpdateConfiguration.metadata_signature.update:undefined), havePermission);
					vm.metadata_signature_delete = getValueConfiguration(((emgdeUpdateConfiguration.metadata_signature)?emgdeUpdateConfiguration.metadata_signature.delete:undefined), havePermission);
					vm.metadata_signature_deleteFirst = getValueConfiguration(((emgdeUpdateConfiguration.metadata_signature)?emgdeUpdateConfiguration.metadata_signature.deleteFirst:undefined), havePermission);
					vm.metadata_signature_create = getValueConfiguration(((emgdeUpdateConfiguration.metadata_signature)?emgdeUpdateConfiguration.metadata_signature.create:undefined), havePermission);
					vm.metadata_signature_format = getValueConfiguration(emgdeUpdateConfiguration.metadata_signature_format, havePermission);
					vm.metadata_signature_profile = getValueConfiguration(emgdeUpdateConfiguration.metadata_signature_profile, havePermission);
					vm.metadata_classification_update = getValueConfiguration(((emgdeUpdateConfiguration.metadata_classification)?emgdeUpdateConfiguration.metadata_classification.update:undefined), havePermission);
					vm.metadata_classification_delete = getValueConfiguration(((emgdeUpdateConfiguration.metadata_classification)?emgdeUpdateConfiguration.metadata_classification.delete:undefined), havePermission);
					vm.metadata_classification_deleteFirst = getValueConfiguration(((emgdeUpdateConfiguration.metadata_classification)?emgdeUpdateConfiguration.metadata_classification.deleteFirst:undefined), havePermission);
					vm.metadata_classification_create = getValueConfiguration(((emgdeUpdateConfiguration.metadata_classification)?emgdeUpdateConfiguration.metadata_classification.create:undefined), havePermission);
					vm.metadata_classification_code = getValueConfiguration(emgdeUpdateConfiguration.metadata_classification_code, havePermission);
					vm.metadata_classification_name = getValueConfiguration(emgdeUpdateConfiguration.metadata_classification_name, havePermission);
					vm.metadata_classification_type = getValueConfiguration(emgdeUpdateConfiguration.metadata_classification_type, havePermission);
					vm.metadata_organ_update = getValueConfiguration(((emgdeUpdateConfiguration.metadata_organ)?emgdeUpdateConfiguration.metadata_organ.update:undefined), havePermission);
					vm.metadata_organ_delete = getValueConfiguration(((emgdeUpdateConfiguration.metadata_organ)?emgdeUpdateConfiguration.metadata_organ.delete:undefined), havePermission);
					vm.metadata_organ_deleteFirst = getValueConfiguration(((emgdeUpdateConfiguration.metadata_organ)?emgdeUpdateConfiguration.metadata_organ.deleteFirst:undefined), havePermission);
					vm.metadata_organ_create = getValueConfiguration(((emgdeUpdateConfiguration.metadata_organ)?emgdeUpdateConfiguration.metadata_organ.create:undefined), havePermission);
					vm.metadata_organ_organ = getValueConfiguration(emgdeUpdateConfiguration.metadata_organ_organ, havePermission);
					vm.metadata_interested_update = getValueConfiguration(((emgdeUpdateConfiguration.metadata_interested)?emgdeUpdateConfiguration.metadata_interested.update:undefined), havePermission);
					vm.metadata_interested_delete = getValueConfiguration(((emgdeUpdateConfiguration.metadata_interested)?emgdeUpdateConfiguration.metadata_interested.delete:undefined), havePermission);
					vm.metadata_interested_deleteFirst = getValueConfiguration(((emgdeUpdateConfiguration.metadata_interested)?emgdeUpdateConfiguration.metadata_interested.deleteFirst:undefined), havePermission);
					vm.metadata_interested_create = getValueConfiguration(((emgdeUpdateConfiguration.metadata_interested)?emgdeUpdateConfiguration.metadata_interested.create:undefined), havePermission);
					vm.metadata_security_classification = getValueConfiguration(emgdeUpdateConfiguration.metadata_security_classification, havePermission);
				}
                if(TramNewFactory.emgdeDossierMetadata) {
                    vm.emgdeDossierMetadata = TramNewFactory.emgdeDossierMetadata;
					vm.isTransferedMode = vm.emgdeDossierMetadata.transfered;
                }
            }
        }]
    })
	
	