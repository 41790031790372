/**
 * Created by apop on 06/09/2018.
 */
angular
    .module('annexaApp')
    .component('annexaCompleteUnguidedDossierTransaction', {
        templateUrl: './components/tram/annexa-complete-unguided-dossier-transaction/annexa-complete-unguided-dossier-transaction.html',
        controller: ['Language', 'TramNewFactory','$rootScope', 'GlobalDataFactory', '$filter', 'globalModals', 'AnnexaFormlyFactory', 'DialogsFactory', 'AnnexaPermissionsFactory', 'apiAdmin', 'RestService', 'SecFactory', function (Language, TramNewFactory, $rootScope, GlobalDataFactory, $filter, globalModals, AnnexaFormlyFactory, DialogsFactory, AnnexaPermissionsFactory, apiAdmin, RestService, SecFactory) {
            var vm = this;
            vm.languageColumn = "language1";
			vm.responsibleUserBoolean = false;
			vm.nextInfo = {};
			vm.transactionsTypes = [];
			vm.tramitationPhase = [];
			vm.expirationTypes = [];
			vm.daysComputed = []; 
			vm.createTransactionTypes = [];
			vm.haveFinalTransaction = false
			vm.showUsersProfiles = false;
			vm.amendmentFiscalizationTransactionTypeId = undefined;
			vm.amendmentFiscalizationTransactionTypeFreeId = undefined;
			vm.disableProfiles = false;
			vm.onlyProfiles = [];
			vm.loadFunctionAux = undefined;

			vm.getProfileUsers = function(profile){
				var names = '';
                if (profile && profile.profile && profile.profile.userProfiles) {
                    angular.forEach(profile.profile.userProfiles, function (value, key) {
                        if (value.user && value.user.name) {
                            names += value.user.name + '  ' + value.user.surename1 + ((value.user.surename2) ? ' ' + value.user.surename2 + '\n' : '\n');
                        } else if(value.user.language1 || value.user.language2 || value.user.language3) {
                        	names += value.user[vm.languageColumn] + '\n';
                        }
                    });
                }
                return names;
			}
			
			vm.getProfileName = function(nextInfo){
				 var name = '';
                if (nextInfo && nextInfo.profile) {
                    name = nextInfo.profile.value;
                    if (nextInfo.selectedResponsible && nextInfo.selectedResponsible.id) {
                    	if(nextInfo.selectedResponsible.name) {
                            name += " (" + nextInfo.selectedResponsible.name + '  ' + nextInfo.selectedResponsible.surename1 + ((nextInfo.selectedResponsible.surename2) ? ' ' + nextInfo.selectedResponsible.surename2 : '') + ")";
                    	} else if(nextInfo.selectedResponsible.language1 || nextInfo.selectedResponsible.language2 || nextInfo.selectedResponsible.language3) {
                            name += " (" + nextInfo.selectedResponsible[vm.languageColumn] + ")";
                    	}
                    }
                }
                return name;
			}
			
			vm.setUserProfiles = function(selectedUser) {
				if(vm.nextInfo && vm.nextInfo.profile && vm.nextInfo.profile.id) {
					SecFactory.getUserProfilesByProfileId(vm.nextInfo.profile.id).then(function(data){
						if(data && data.length > 0){
							vm.nextInfo.profile.profile.userProfiles = data;
		            		if(selectedUser) {
		            			vm.nextInfo.selectedResponsible = $linq(vm.nextInfo.profile.profile.userProfiles).firstOrDefault(undefined, "x => x.user.id == " + selectedUser.id).user;
		            		}
						}
					}).catch(function(error){});
				}
			}
			
			vm.selectTransactionType = function () {
				vm.disableProfiles = false;
				vm.onlyProfiles = [];
				var createTransactionType = $linq(vm.createTransactionTypes).firstOrDefault(undefined, "x => x.transactionType.id == " + vm.nextInfo.transactionType.id);
                vm.nextInfo.maxDays = vm.nextInfo.transactionType.days;
                vm.nextInfo.expirationType = {
                	description: "global.literals." + vm.nextInfo.transactionType.expireType,
                	id: vm.nextInfo.transactionType.expireType
                }
                if (vm.nextInfo.maxDays && vm.data.dossier && vm.data.dossier.dossierUrgent) {
                    vm.nextInfo.maxDays = Math.round(vm.nextInfo.maxDays / 2);
                }
                vm.nextInfo.daysComputeGlobal = vm.nextInfo.transactionType.countDays;
                vm.nextInfo.allowUserChangeCountDays = vm.nextInfo.transactionType.allowUserChangeCountDays;
                vm.nextInfo.allowUserChangeMaxDays = vm.nextInfo.transactionType.allowUserChangeMaxDays;
                vm.nextInfo.profile = undefined;
                vm.nextInfo.selectedResponsible = undefined;
                if(createTransactionType && createTransactionType.assignationTypeTram) {
                	vm.setProfiles(createTransactionType);
                }
                vm.tramitationPhase = $linq(vm.nextInfo.transactionType.phases).select("x => x.tramitationPhase").toArray();
                if(vm.tramitationPhase.length == 1){
                    vm.nextInfo.tramitationPhase = vm.tramitationPhase[0];
                }else if(vm.tramitationPhase.length > 1){
                    vm.nextInfo.tramitationPhase = undefined;
                }
            };
            
            vm.setProfiles = function(createTransactionType){
    			vm.data.loadFunction = vm.loadFunctionAux;
    			vm.showUsersProfiles = (($rootScope.app && $rootScope.app.configuration &&  $rootScope.app.configuration.assign_responsible_user && $rootScope.app.configuration.assign_responsible_user.dossierTransaction && 
					($rootScope.app.configuration.assign_responsible_user.dossierTransaction == 'Obligatoria' || $rootScope.app.configuration.assign_responsible_user.dossierTransaction == 'Opcional')) || $rootScope.esetMode);
            	if(createTransactionType.assignationTypeTram == 'INHERITED' && createTransactionType.inheritedAssignationTypeTram) {
            		var selectedUser = undefined;
            		vm.nextInfo.profile = undefined;
            		if(createTransactionType.inheritedAssignationTypeTram == 'STARTTRAM') {
            			var startTram = $linq(vm.data.dossier.dossierTransactions).orderBy("x => x.createdDate").thenBy("x => x.id").first();
            			vm.nextInfo.profile = { id: startTram.processProfiles[0].profile.id, value: startTram.processProfiles[0].profile[Language.getActiveColumn()], profile: startTram.processProfiles[0].profile};
            			vm.disableProfiles = true;
            			if(vm.showUsersProfiles && startTram.responsibleUser) {
            				selectedUser = startTram.responsibleUser;
            			}
            		} else if(createTransactionType.inheritedAssignationTypeTram == 'PREVIOUSTRAM') {
            			if(vm.data.isCompleteTransaction) {
            				vm.nextInfo.profile = { id: vm.data.transaction.processProfiles[0].profile.id, value: vm.data.transaction.processProfiles[0].profile[Language.getActiveColumn()], profile: vm.data.transaction.processProfiles[0].profile};
            				vm.disableProfiles = true;
                			if(vm.showUsersProfiles && vm.data.transaction.responsibleUser) {
                				selectedUser = vm.data.transaction.responsibleUser;
                			}
            			} else {
            				var selectProfiles = [];
            				_.forEach(vm.data.dossier.dossierTransactions, function(dt){
            					_.forEach(dt.processProfiles, function(pp){
            						if(!$linq(selectProfiles).contains(pp.profile, "(x, y) => x.id == y.id")){
                						selectProfiles.push(pp.profile);
            						}
        						});
    						});
            				if(selectProfiles.length > 1){
            					vm.onlyProfiles = $linq(selectProfiles).select("x => x.id").toArray()
            				} else if (selectProfiles.length == 1) {
            					vm.nextInfo.profile = { id: selectProfiles[0].id, value: selectProfiles[0][Language.getActiveColumn()], profile: selectProfiles[0]}
            					vm.disableProfiles = true;
            				}
            			}
            		} else if(createTransactionType.inheritedAssignationTypeTram == 'DOSSIERRESP') {
            			if(vm.data.dossier.dossierResponsibleProfiles && vm.data.dossier.dossierResponsibleProfiles.length > 1) {
            				vm.onlyProfiles = $linq(vm.data.dossier.dossierResponsibleProfiles).select("x => x.profile.id").toArray();
            				if(vm.showUsersProfiles && vm.data.dossier.responsibleUser) {
            					selectedUser = vm.data.dossier.responsibleUser;
            				}
            			} else if(vm.data.dossier.dossierResponsibleProfiles && vm.data.dossier.dossierResponsibleProfiles.length == 1) {
            				vm.nextInfo.profile = { id: vm.data.dossier.dossierResponsibleProfiles[0].profile.id, value: vm.data.dossier.dossierResponsibleProfiles[0].profile[Language.getActiveColumn()], profile: vm.data.dossier.dossierResponsibleProfiles[0].profile};
            				vm.disableProfiles = true;
            				if(vm.showUsersProfiles && vm.data.dossier.responsibleUser) {
            					selectedUser = vm.data.dossier.responsibleUser;
            				}
            			}
            		}
            		vm.setUserProfiles(selectedUser);
            	} else if(createTransactionType.assignationTypeTram == 'GUIDED' && createTransactionType.profiles && createTransactionType.profiles.length > 0) {
            		vm.nextInfo.profile = undefined;
            		var isSelectedProfile = false;
            		var isSelectedUser = false;
                    if(vm.data.dossier && vm.data.dossier.customFields) {
                    	if(createTransactionType.useCustomFieldProfile && createTransactionType.customFieldProfile) {
                    		var customFieldProfileDossier = $linq(vm.data.dossier.customFields).firstOrDefault(undefined, "x => x.customField.id == " + createTransactionType.customFieldProfile.customField.id);
                    		if(customFieldProfileDossier) {
                    			if(!customFieldProfileDossier.valueFromJSON) {
                    				try {
                            			var object = angular.fromJson(customFieldProfileDossier.value.replace(/(?:\r\n|\r|\n)/g, '<br/>'));
            	                        if(object.value && object.value.replace) {
            	                        	customFieldProfileDossier.valueFromJSON = object.value.replace(/<br\s*\/?>/gi, '\n');
            	                        }else {
            	                        	customFieldProfileDossier.valueFromJSON = object.value;
            	                        }
                    				} catch (error) {
                        				console.error(error);
                        				customFieldProfileDossier.valueFromJSON = undefined;
	    	                        }
                    			}
                    			if(customFieldProfileDossier.valueFromJSON) {
                    				var selProf = undefined;
                    				if(Array.isArray(customFieldProfileDossier.valueFromJSON) && customFieldProfileDossier.valueFromJSON.length > 0) {
                    					selProf = [];
                    					_.forEach(customFieldProfileDossier.valueFromJSON, function(id){
                        					selProf.push($linq(customFieldProfileDossier.customField.listValues).firstOrDefault(undefined, "x => x.id == " + id));
                    					});
                            			vm.onlyProfiles = $linq(selProf).select("x => x.id").toArray();
                            			isSelectedProfile = true;
                    				} else if(!Array.isArray(customFieldProfileDossier.valueFromJSON)) {
                    					selProf = $linq(customFieldProfileDossier.customField.listValues).firstOrDefault(undefined, "x => x.id == " + customFieldProfileDossier.valueFromJSON);
                        				if(selProf) {
                        					vm.nextInfo.profile = { id: selProf.id, value: selProf[Language.getActiveColumn()], profile: selProf};
                                			vm.disableProfiles = true;
                                			isSelectedProfile = true;
                        				}
                    				}
                    			}
                    		}
                    	}
                    	if(createTransactionType.useCustomFieldUser && createTransactionType.customFieldUser) {
                    		var customFieldUserDossier = $linq(vm.data.dossier.customFields).firstOrDefault(undefined, "x => x.customField.id == " + createTransactionType.customFieldUser.customField.id);
                        	if(customFieldUserDossier) {
                        		if(!customFieldUserDossier.valueFromJSON) {
                        			try{
                        				var object = angular.fromJson(customFieldUserDossier.value.replace(/(?:\r\n|\r|\n)/g, '<br/>'));
                        				if(object.value && object.value.replace) {
    	    	                        	customFieldUserDossier.valueFromJSON = object.value.replace(/<br\s*\/?>/gi, '\n');
    	    	                        }else {
    	    	                        	customFieldUserDossier.valueFromJSON = object.value;
    	    	                        }
                        			} catch (error) {
                        				console.error(error);
                        				customFieldUserDossier.valueFromJSON = undefined;
	    	                        }
	                			}
                    			if(customFieldUserDossier.valueFromJSON) {
                    				var selUser = undefined;
                    				if(Array.isArray(customFieldUserDossier.valueFromJSON) && customFieldUserDossier.valueFromJSON.length > 0) {
                    					selUser = [];
                    					_.forEach(customFieldUserDossier.valueFromJSON, function(id){
                    						var usr = {};
                    						usr.user = $linq(customFieldUserDossier.customField.listValues).firstOrDefault(undefined, "x => x.id == " + id)
                    						selUser.push(usr);
                    					});
                        				isSelectedUser = true;
                            			vm.showUsersProfiles = true;
                    				} else if(!Array.isArray(customFieldUserDossier.valueFromJSON)) {
                    					selUser = {};
                    					selUser.user = $linq(customFieldUserDossier.customField.listValues).firstOrDefault(undefined, "x => x.id == " + customFieldUserDossier.valueFromJSON);
                        				isSelectedUser = true;
                            			vm.showUsersProfiles = true;
                    				}
                    			}
                        	}
                    	}
                    }
            		
            		
            		if(!isSelectedProfile && createTransactionType.profiles.length == 1) {
            			vm.nextInfo.profile = { id: createTransactionType.profiles[0].profile.id, value: createTransactionType.profiles[0].profile[Language.getActiveColumn()], profile: createTransactionType.profiles[0].profile};
            			vm.disableProfiles = true;
            		} else if(!isSelectedProfile){
            			vm.onlyProfiles = $linq(createTransactionType.profiles).select("x => x.profile").select("x => x.id").toArray();
            		}
            		if(isSelectedUser || (createTransactionType.users && createTransactionType.users.length > 0)) {
            			 if(isSelectedUser && selUser && vm.nextInfo.profile && vm.nextInfo.profile.profile && !vm.nextInfo.profile.userProfiles) {
             				vm.nextInfo.profile.profile.userProfiles = [];
             				if(Array.isArray(selUser)) {
             					var prof = $linq(GlobalDataFactory.allProfiles).firstOrDefault(undefined, "x => x.id == " + vm.nextInfo.profile.profile.id);
 								if(prof && prof.userProfiles) {
 									_.forEach(selUser, function(userToCheck){
 										if($linq(prof.userProfiles).contains(userToCheck, function(x,y){
 				   	   						if(y && y.user && y.user.id && x.user && x.user.id == y.user.id) { return true; } else { return false; } 
 				   	   					})) {
 	 										vm.nextInfo.profile.profile.userProfiles.push(userToCheck);
 	            						}
 									})
 								}
 							} else {
 								var prof = $linq(GlobalDataFactory.allProfiles).firstOrDefault(undefined, "x => x.id == " + vm.nextInfo.profile.profile.id);
 								if(prof && prof.userProfiles) {
 									if($linq(prof.userProfiles).contains(selUser, function(x,y){
			   	   						if(y && y.user && y.user.id && x.user && x.user.id == y.user.id) { return true; } else { return false; } 
			   	   					})) {
 										vm.nextInfo.profile.profile.userProfiles.push(selUser);
            						}
 								}
     						}
             			} else if(createTransactionType.users && createTransactionType.users.length > 0 && vm.nextInfo.profile 
            					&& vm.nextInfo.profile.profile && !vm.nextInfo.profile.userProfiles) {
            				vm.nextInfo.profile.profile.userProfiles = createTransactionType.users;
            			}
            			vm.showUsersProfiles = true;
            			vm.data.loadFunction = function(value, only) {
            				var createTransactionType = $linq(vm.createTransactionTypes).firstOrDefault(undefined, "x => x.transactionType.id == " + vm.nextInfo.transactionType.id);
            				if(isSelectedUser) {
            					var customFieldUserDossier = $linq(vm.data.dossier.customFields).firstOrDefault(undefined, "x => x.customField.id == " + createTransactionType.customFieldUser.customField.id);
            					if(customFieldUserDossier) {
            						if(customFieldUserDossier.valueFromJSON) {
                        				var selUser = undefined;
                        				if(Array.isArray(customFieldUserDossier.valueFromJSON)) {
                        					selUser = [];
                        					_.forEach(customFieldUserDossier.valueFromJSON, function(id){
                        						var usr = {};
                        						usr.user = $linq(customFieldUserDossier.customField.listValues).firstOrDefault(undefined, "x => x.id == " + id);
                        						selUser.push(usr);
                        					});
                        				} else {
                        					selUser = {};
                        					selUser.user = $linq(customFieldUserDossier.customField.listValues).firstOrDefault(undefined, "x => x.id == " + customFieldUserDossier.valueFromJSON);
                        				}
            						}
            					}
            				}
            				return TramNewFactory.searchProfiles(value, only).then(function(items) {
            					_.forEach(items, function(pro){
            						var userProfiles = [];
            						var defaultUserProfiles = [];
            						_.forEach(pro.profile.userProfiles, function(usrProfile){
            							if(selUser) {
            								if(Array.isArray(selUser)) {
            									if($linq(selUser).contains(usrProfile.user, function(x,y){
        				   	   						if(x && x.user && x.user.id && y.id == x.user.id) { return true; } else { return false; } 
        				   	   					})) {
                        							userProfiles.push(usrProfile);
                        						}
            								} else if(selUser.user.id == usrProfile.user.id) {
                    							userProfiles.push(usrProfile);
                    						}
            								defaultUserProfiles.push(usrProfile);
            							} else {
            								if($linq(createTransactionType.users).contains(usrProfile.user, function(x,y){
    				   	   						if(x && x.user && x.user.id && y.id == x.user.id) { return true; } else { return false; } 
    				   	   					})) {
                    							userProfiles.push(usrProfile);
                    						}
            							}
            						});
            						if(selUser && userProfiles && userProfiles.length == 0) {
            							pro.profile.userProfiles = defaultUserProfiles;
            						} else {
            							pro.profile.userProfiles = userProfiles;
            						}
        						});
            					return items;
    		                }).catch(function (error) {
    		                	console.error(error);
    		            	});
            			}
            		}
            	}
            }

			vm.removeFunction = function(index){
				if (vm.data && vm.data.transactionNoGuiadaList && vm.data.transactionNoGuiadaList.length > index) {
                    vm.data.transactionNoGuiadaList.splice(index, 1);
                    vm.createTransactionTypeList();
                }
			}

			vm.clearTransactionType = function () {
                vm.disableProfiles = false;
				vm.onlyProfiles = [];
                vm.nextInfo.transactionType = undefined;
                vm.nextInfo.expirationType = undefined;
                vm.data.loadFunction = vm.loadFunctionAux;
                vm.showUsersProfiles = (($rootScope.app && $rootScope.app.configuration &&  $rootScope.app.configuration.assign_responsible_user && $rootScope.app.configuration.assign_responsible_user.dossierTransaction && 
        			($rootScope.app.configuration.assign_responsible_user.dossierTransaction == 'Obligatoria' || $rootScope.app.configuration.assign_responsible_user.dossierTransaction == 'Opcional')) || $rootScope.esetMode);
            };

            vm.clearExpirationType = function () {
                vm.nextInfo.expirationType = undefined;
            };
            
            vm.clearTramitationPhase = function () {
            	vm.nextInfo.tramitationPhase = undefined;
            };
            
			vm.showAdd = function(){
				var show = false;
                if (vm.nextInfo && vm.nextInfo.profile && vm.nextInfo.profile.id && vm.nextInfo.transactionType && vm.nextInfo.transactionType.id && vm.nextInfo.maxDays > 0 && vm.nextInfo.expirationType) {
                    if (($rootScope.esetMode || ($rootScope.app && $rootScope.app.configuration &&  $rootScope.app.configuration.assign_responsible_user && $rootScope.app.configuration.assign_responsible_user.dossierTransaction && 
					$rootScope.app.configuration.assign_responsible_user.dossierTransaction == 'Obligatoria')) && vm.nextInfo.selectedResponsible) {
                        show = true;
                    } else if (!$rootScope.esetMode && ($rootScope.app && $rootScope.app.configuration &&  $rootScope.app.configuration.assign_responsible_user && $rootScope.app.configuration.assign_responsible_user.dossierTransaction && 
					$rootScope.app.configuration.assign_responsible_user.dossierTransaction != 'Obligatoria')) {
                        show = true;
                    }
                }
				if(show && vm.nextInfo && vm.nextInfo.transactionType && (vm.nextInfo.transactionType.id == vm.amendmentFiscalizationTransactionTypeId || vm.nextInfo.transactionType.id == vm.amendmentFiscalizationTransactionTypeFreeId)){
					if(!vm.nextInfo.amendmentReason){
						show = false;
					}
				}
                return show;
			}
			
			vm.addFunction = function(){
				if (vm.nextInfo && vm.nextInfo.transactionType && vm.nextInfo.transactionType.finallyType) {
                    vm.createTransactionTypeList();
                }
                vm.data.transactionNoGuiadaList.push(vm.nextInfo);
                vm.nextInfo = {};
                vm.disableProfiles = false;
				vm.onlyProfiles = [];
                vm.responsibleUserBoolean = false;
                vm.data.loadFunction = vm.loadFunctionAux;
                if (vm.data && vm.data.dossier && TramNewFactory.isResponsible(vm.data.dossier)) {
                	if (!vm.data.sendProposal){
                		vm.responsibleUserBoolean = true;
                	}else{
                		if(!vm.data.transactionNoGuiadaList || vm.data.transactionNoGuiadaList.length == 0){
                			vm.responsibleUserBoolean = true;
                		}
                	}
                }
			}
			
			vm.isOmissionTransaction = function(){
            	if(vm.data && vm.data.transaction && vm.data.transaction.transactionType && vm.data.transaction.transactionType.transactionSubtype == apiAdmin.transactionSubtypes[11].id){
            		return true;
            	}else if(vm.data && vm.data.transaction && vm.data.transaction.transactionType && vm.data.transaction.transactionType.transactionSubtype == apiAdmin.transactionSubtypes[13].id){
            		return true;
            	}else{
            		return false;
            	}                	
			}
			vm.createTransactionTypeList = function () {
				if (vm.data && vm.data.dossier && vm.data.dossier.dossierTramitationType) {
					vm.transactionsTypes = [];
					var select = "x => x.active == 1";
					_.forEach(GlobalDataFactory.transactiontypes, function (value, key) {
						if($linq(value.tramitationTypes).contains(vm.data.dossier.dossierTramitationType.id, "(x, y) => x.tramitationType.id == y")) {
							vm.transactionsTypes.push(value);
						}
                    });
	                if ($rootScope.esetMode) {
	                    if (vm.haveFinalTransaction && vm.data.dossier.dossierUrgent) {
	                    	vm.transactionsTypes = [];
	                    	_.forEach(GlobalDataFactory.transactiontypes, function (value, key) {
	                    		if(value.finallyType == false && $linq(value.tramitationTypes).contains(true, function(x,y){
									if(x.tramitationType.allowUrgentProcess == y && x.tramitationType.id == vm.data.dossier.dossierTramitationType.id) { return true; } else { return false; } 
								})) {
									vm.transactionsTypes.push(value);
								}
	                        });
	                    } else if (vm.haveFinalTransaction) {
	                    	select += " && x.finallyType == false";
	                    } else if (vm.data.dossier.dossierUrgent) {
	                    	vm.transactionsTypes = [];
	                    	_.forEach(GlobalDataFactory.transactiontypes, function (value, key) {
	                    		if($linq(value.tramitationTypes).contains(true, function(x,y){
									if(x.tramitationType.allowUrgentProcess == y && x.tramitationType.id == vm.data.dossier.dossierTramitationType.id) { return true; } else { return false; } 
								})) {
									vm.transactionsTypes.push(value);
								}
	                        });
	                    }
	                } else {
	                    if (vm.data.dossier.dossierUrgent) {
	                    	vm.transactionsTypes = [];
	                    	_.forEach(GlobalDataFactory.transactiontypes, function (value, key) {
								if($linq(value.tramitationTypes).contains(true, function(x,y){
									if(x.tramitationType.allowUrgentProcess == y && x.tramitationType.id == vm.data.dossier.dossierTramitationType.id) { return true; } else { return false; } 
								})) {
									vm.transactionsTypes.push(value);
								}
	                        });
	                    }
	                }
	                if (vm.data.sendProposal) {
	                	if(vm.data.nextDepartment){
	                    	//si s'envia la proposta a fiscalització de departament es filtrarà només pel subtipus de tràmit 'INSPECTION_DEPARTMENT'
	                    	select += " && x.transactionSubtype == " + apiAdmin.transactionSubtypes[6].id;
	                	}else if(vm.data.nextIntervention){
	                		//si s'envia la proposta a fiscalització d'intervenció es filtrarà només pel subtipus de tràmit 'INSPECTION_INTERVENTION'
	                		select += " && x.transactionSubtype == " + apiAdmin.transactionSubtypes[5].id;
	                	}else{
	                        //si s'envia la proposta es filtrarà només pel subtipus de tràmit 'Acord'
	                    	select += " && x.transactionSubtype == " + apiAdmin.transactionSubtypes[2].id;
	                	}
	                } else if (vm.data.sendInspectionProposal) {
	                	if(vm.data.nextDepartment){
	                    	//si s'envia la proposta a fiscalització de departament es filtrarà només pel subtipus de tràmit 'INSPECTION_DEPARTMENT'
	                    	select += " && x.transactionSubtype == " + apiAdmin.transactionSubtypes[9].id;
	                	}else if(vm.data.nextIntervention){
	                		//si s'envia la proposta a fiscalització d'intervenció es filtrarà només pel subtipus de tràmit 'INSPECTION_INTERVENTION_FREE'
	                		select += " && x.transactionSubtype == " + apiAdmin.transactionSubtypes[8].id;
	                	}else{
	                        //si s'envia la proposta es filtrarà només pel subtipus de tràmit 'Finalització de fiscalització'
	                    	select += " && x.transactionSubtype == " + apiAdmin.transactionSubtypes[10].id;
	                	}
	                }else if(vm.data.omission){
	                	if (vm.data.free) {
	                		select += " && x.transactionSubtype == " + apiAdmin.transactionSubtypes[13].id;
	                	}else{
	                		select += " && x.transactionSubtype == " + apiAdmin.transactionSubtypes[11].id;
	                	}
	                }else if(vm.data.reparation){
	                	if (vm.data.free) {
	                    	select += " && x.transactionSubtype == " + apiAdmin.transactionSubtypes[14].id;
	                	}else{
	                		select += " && x.transactionSubtype == " + apiAdmin.transactionSubtypes[12].id;
	                	}
	                }else if(vm.data.transaction && vm.data.transaction.transactionType && vm.data.transaction.transactionType.transactionSubtype == apiAdmin.transactionSubtypes[11].id){  
	                   	select += " && x.transactionSubtype == " + apiAdmin.transactionSubtypes[2].id;
	            	}else if(vm.data.transaction && vm.data.transaction.transactionType && vm.data.transaction.transactionType.transactionSubtype == apiAdmin.transactionSubtypes[13].id){  
	           			select += " && x.transactionSubtype == " + apiAdmin.transactionSubtypes[10].id;
	                } else {
	                	//en cas contrari es filtrarà pels subtipus de tràmit 'Estandard' i 'Proposta' i si el dossier permet fiscalització 'START_INSPECTION_FREE'
	                	var active = false;
	             	    var showDepartmentOrInterventionFree = undefined;
	             	    if(vm.data.procedureProposalTypeInspectionInterventionActive !== undefined){
	             	    	if(vm.data.procedureProposalTypeInspectionInterventionActive){
	             	    		active = true;
	             	    	}
	             	    }else if(vm.data.dossier && vm.data.dossier.procedure && vm.data.dossier.procedure.inspectionIntervention != undefined){
	               		    if(vm.data.dossier.procedure.inspectionIntervention){
	               			    active = true;
	               		    }
	               	    }else{
	               		    var arcClassification = undefined;
	               		    if(vm.data.dossier && vm.data.dossier.archiveClassification && vm.data.dossier.archiveClassification.model && vm.data.dossier.archiveClassification.model.$selected && vm.data.dossier.archiveClassification.model.$selected.id){
	               			    arcClassification = $linq(GlobalDataFactory.archiveClassifications).firstOrDefault(undefined,"x => x.id == "+vm.data.dossier.archiveClassification.model.$selected.id);
	               		    }
	               		    if(arcClassification && arcClassification.inspectionIntervention){
	               			    active = true;
	               		    }else{
	               			    if(vm.data.dossier && vm.data.dossier.procedure.archiveClassification && vm.data.dossier.procedure.archiveClassification.id){
	                   			    arcClassification = $linq(GlobalDataFactory.archiveClassifications).firstOrDefault(undefined,"x => x.id == "+vm.data.dossier.procedure.archiveClassification.id);
	                   		    }
	               			    if(arcClassification && arcClassification.inspectionIntervention){
	                   			    active = true;
	                   		    }
	               		    }
	               	    }
	                 	if(active){
	                		select += " && (x.transactionSubtype == " + apiAdmin.transactionSubtypes[0].id + " || x.transactionSubtype == " + apiAdmin.transactionSubtypes[1].id + " || x.transactionSubtype == " + apiAdmin.transactionSubtypes[7].id + ")";
	                 	}else{
	                		select += " && (x.transactionSubtype == " + apiAdmin.transactionSubtypes[0].id + " || x.transactionSubtype == " + apiAdmin.transactionSubtypes[1].id + ")";
	                	}
	                }
	                vm.transactionsTypes = $linq(vm.transactionsTypes).where(select).toArray();
	            }
	            if (vm.data && vm.data.atributes && vm.data.atributes.backbutton && vm.data.dossier.procedure && vm.data.dossier.procedure.transactionTypes) {
	                vm.transactionsTypes = $linq(vm.data.dossier.procedure.transactionTypes).select("x => x.transactionType && x.active == 1").orderBy("x => x." + Language.getActiveColumn(), HelperService.caseInsensitiveOrNullComparer).toArray();
	            }
	            
	            if(vm.data && vm.data.dossier && vm.data.dossier.procedure && vm.data.dossier.procedure.createTransactionTypes && vm.data.dossier.procedure.createTransactionTypes.length > 0 && vm.transactionsTypes && vm.transactionsTypes.length > 0){
	            	vm.createTransactionTypes = vm.data.dossier.procedure.createTransactionTypes;
	                if ($rootScope.app && $rootScope.app.configuration &&  $rootScope.app.configuration.check_preconditions_transtype_list 
	                		&& $rootScope.app.configuration.check_preconditions_transtype_list.value) {
	                	var dtId = -1;
	                	if(vm.data && vm.data.transaction && vm.data.transaction.id) {
	                		dtId = vm.data.transaction.id;
	                	}
	                	TramNewFactory.filterListTransactionTypeByPreSpel(vm.data.dossier.id, dtId).then(function(data) {
	                		vm.transactionsTypes = data;
		                }).catch(function (error) {
		            	});	
	                } else  {
	                	vm.transactionsTypes = $linq(vm.transactionsTypes).intersect(vm.createTransactionTypes, function(x,y){
	                		if(y.id && x.transactionType && x.transactionType.id) {
	                			return y.id == x.transactionType.id;
	                		} else {
	                			return false;
	                		}
	                	}).toArray();
	                	
	                	if(vm.createTransactionTypes && vm.createTransactionTypes) {
	                		vm.createTransactionTypes = $linq(vm.createTransactionTypes).orderBy("x => x.viewOrder").toArray()
	                		var createTransactionTypesAux = []
	                		_.forEach(vm.createTransactionTypes, function(value){
	                			var transactionType = $linq(vm.transactionsTypes).where("x => x.id == " + value.transactionType.id).firstOrDefault();
	                			if(transactionType){
	                				createTransactionTypesAux.push(transactionType)
	                			}
	                		});
	                		angular.copy(createTransactionTypesAux, vm.transactionsTypes)
	                	}
	                }
	            }
	
				if (vm.data && (vm.data.sendProposal || ((vm.data.omission || vm.data.reparation) && !vm.data.free) || (vm.data.transaction && vm.data.transaction.transactionType && vm.data.transaction.transactionType.transactionSubtype == apiAdmin.transactionSubtypes[11].id))) {
	            	vm.completeNextInfoSendProposal();
	            }
	
	            if (vm.data && (vm.data.sendInspectionProposal || ((vm.data.omission || vm.data.reparation) && vm.data.free) || (vm.data.transaction && vm.data.transaction.transactionType && vm.data.transaction.transactionType.transactionSubtype == apiAdmin.transactionSubtypes[13].id))) {
	            	vm.completeNextInfoSendInspectionProposal();
	            }
            }

			vm.completeNextInfoSendProposal = function () {
				var pdt = ((vm.data.proposalsDossierTransaction)?$linq(vm.data.proposalsDossierTransaction).lastOrDefault(undefined, "x => x.proposalDossierTransactionType == 'PROPOSAL'"):undefined);
            	if(vm.data.transaction && vm.data.transaction.id){
                  	var proposals = $linq(vm.data.proposalsDossierTransaction).where("x => x.dossierTransaction.id == " + vm.data.transaction.id).select("x => x.proposal").toArray();
                  	if (proposals && proposals.length > 0) {
                  		var proposal = $linq(vm.data.proposalsDossierTransaction).lastOrDefault(undefined, "x => x.proposalDossierTransactionType == 'PROPOSAL' && x.proposal && x.proposal.id == "+proposals[0].id);
                  		if(proposal && proposal.id){
                  			pdt = proposal;
                  		}
                  	}
            	}
            	//si s'envia la proposta s'hauran d'omplir els camps
            	if(vm.data.nextDepartment){
            		var prof =((pdt && pdt.dossierTransaction && pdt.dossierTransaction.processProfiles && pdt.dossierTransaction.processProfiles.length > 0)?pdt.dossierTransaction.processProfiles[0].profile:undefined);
            		if(GlobalDataFactory.inspectionDepartmentProfile){
            			prof = GlobalDataFactory.inspectionDepartmentProfile;
            		}else if($rootScope.app.configuration.secretary_properties.inspection_department_profile){
            			prof = $linq(GlobalDataFactory.allProfiles).singleOrDefault(undefined, "x => x.id == " + $rootScope.app.configuration.secretary_properties.inspection_department_profile);
            		}
            		vm.nextInfo = {
                		name: $filter('translate')('global.literals.inspectionOf') + pdt.dossierTransaction[vm.languageColumn],
                		transactionType: (vm.transactionsTypes && vm.transactionsTypes.length == 1) ? vm.transactionsTypes[0] : '',
                		profile: {
	                    	id: ((prof)?prof.id:undefined),
	                    	value:((prof)?prof[vm.languageColumn]:undefined),
	                    	profile: prof
	                    }
                	};
            	}else if(vm.data.nextIntervention){
            		var prof = GlobalDataFactory.interventionProfile;
            		if(!GlobalDataFactory.interventionProfile){
            			prof = $linq(GlobalDataFactory.allProfiles).singleOrDefault(undefined, "x => x.id == " + $rootScope.app.configuration.secretary_properties.intervention_profile);
            		}
            		vm.nextInfo = {
                		name: $filter('translate')('global.literals.inspectionOf') + pdt.dossierTransaction[vm.languageColumn],
                		transactionType: (vm.transactionsTypes && vm.transactionsTypes.length == 1) ? vm.transactionsTypes[0] : '',
        				profile: {
                			id: ((prof)?prof.id:undefined),
                			value:((prof)?prof[vm.languageColumn]:undefined),
                			profile: prof
                		}
                	};
            	}else if(vm.data.omission || vm.data.reparation){
            		var prof =((pdt && pdt.dossierTransaction && pdt.dossierTransaction.processProfiles && pdt.dossierTransaction.processProfiles.length > 0)?pdt.dossierTransaction.processProfiles[0].profile:undefined); 
            		vm.nextInfo = {
                		transactionType: (vm.transactionsTypes && vm.transactionsTypes.length == 1) ? vm.transactionsTypes[0] : '',
        				profile: {
                			id: ((prof)?prof.id:undefined),
                			value:((prof)?prof[vm.languageColumn]:undefined),
                			profile: prof
                		}
                	};
            	}else{
					var secretaryProfileAux = GlobalDataFactory.secretaryProfile;
            		if(!secretaryProfileAux){
            			secretaryProfileAux = $linq(GlobalDataFactory.allProfiles).singleOrDefault(undefined, "x => x.id == " + $rootScope.app.configuration.secretary_properties.secretary_profile);
            		}
            		var prof = secretaryProfileAux
            		vm.nextInfo = {
                		name: $filter('translate')('global.literals.manageOf') + pdt.dossierTransaction[vm.languageColumn],
                		transactionType: (vm.transactionsTypes && vm.transactionsTypes.length == 1) ? vm.transactionsTypes[0] : '',
        				profile: {
                			id: ((prof)?prof.id:undefined),
                			value:((prof)?prof[vm.languageColumn]:undefined),
                			profile: prof
                		}
                	};
            	}
            	if (vm.nextInfo.transactionType) vm.selectTransactionType();
    		}

			vm.completeNextInfoSendInspectionProposal = function () {
            	var pdt = {dossierTransaction : {}};
            	if(vm.data && vm.data.transaction){
                	var inspectionProposals = ((vm.data.inspectionProposalsDossierTransaction)?$linq(vm.data.inspectionProposalsDossierTransaction).where("x => x.dossierTransaction.id == " + vm.data.transaction.id).select("x => x.inspectionProposal").toArray():[]);
                	if (inspectionProposals && inspectionProposals.length > 0) {
                		pdt = $linq(vm.data.inspectionProposalsDossierTransaction).lastOrDefault(undefined, "x => x.inspectionProposalDossierTransactionType == 'PROPOSAL' && x.inspectionProposal && x.inspectionProposal.id == "+ inspectionProposals[0].id); 
                	}else{
                		pdt = {dossierTransaction: vm.data.transaction.id}
                	}
            	}
            	//si s'envia la proposta s'hauran d'omplir els camps
            	if(vm.data.nextDepartment){
            		var prof = ((pdt && pdt.dossierTransaction && pdt.dossierTransaction.processProfiles && pdt.dossierTransaction.processProfiles.length > 0)?pdt.dossierTransaction.processProfiles[0].profile:undefined); 
            		if(GlobalDataFactory.inspectionDepartmentProfile){
            			prof = GlobalDataFactory.inspectionDepartmentProfile;
            		}else if($rootScope.app.configuration.secretary_properties.inspection_department_profile){
            			prof = $linq(GlobalDataFactory.allProfiles).singleOrDefault(undefined, "x => x.id == " + $rootScope.app.configuration.secretary_properties.inspection_department_profile);
            		}
            		vm.nextInfo = {
                		name: $filter('translate')('global.literals.inspectionOf') + pdt.dossierTransaction[vm.languageColumn],
                		transactionType: (vm.transactionsTypes && vm.transactionsTypes.length == 1) ? vm.transactionsTypes[0] : '',
                		profile: {
                			id: ((prof)?prof.id:undefined),
                			value:((prof)?prof[vm.languageColumn]:undefined),
                			profile: prof
                		} 
                	};
            	}else if(vm.data.nextIntervention){
            		var prof = GlobalDataFactory.interventionProfile;
            		if(!GlobalDataFactory.interventionProfile){
            			prof = $linq(GlobalDataFactory.allProfiles).singleOrDefault(undefined, "x => x.id == " + $rootScope.app.configuration.secretary_properties.intervention_profile);
            		}
            		vm.nextInfo = {
                		name: $filter('translate')('global.literals.inspectionOf') + pdt.dossierTransaction[vm.languageColumn],
                		transactionType: (vm.transactionsTypes && vm.transactionsTypes.length == 1) ? vm.transactionsTypes[0] : '',
        				profile: {
                			id: ((prof)?prof.id:undefined),
                			value:((prof)?prof[vm.languageColumn]:undefined),
                			profile: prof
                		}
                	};
            	}else if(vm.data.omission || vm.data.reparation){
            		var prof = ((pdt && pdt.dossierTransaction && pdt.dossierTransaction.processProfiles && pdt.dossierTransaction.processProfiles.length > 0)?pdt.dossierTransaction.processProfiles[0].profile:undefined);
            		vm.nextInfo = {
                		transactionType: (vm.transactionsTypes && vm.transactionsTypes.length == 1) ? vm.transactionsTypes[0] : '',
        				profile: {
                			id: ((prof)?prof.id:undefined),
                			value:((prof)?prof[vm.languageColumn]:undefined),
                			profile: prof
                		}
                	};
                }else{
                	var prof = ((pdt && pdt.dossierTransaction && pdt.dossierTransaction.processProfiles && pdt.dossierTransaction.processProfiles.length > 0)?pdt.dossierTransaction.processProfiles[0].profile:undefined);
            		vm.nextInfo = {
                		name: $filter('translate')('global.literals.finallyOf') + pdt.dossierTransaction[vm.languageColumn],
                		transactionType: (vm.transactionsTypes && vm.transactionsTypes.length == 1) ? vm.transactionsTypes[0] : '',
        				profile: {
                			id: ((prof)?prof.id:undefined),
                			value:((prof)?prof[vm.languageColumn]:undefined),
                			profile: prof
                		}
                	};
            	}
            	if (vm.nextInfo.transactionType) vm.selectTransactionType();
            }
            this.$onInit = function () {
            	vm.loadFunctionAux = vm.data.loadFunction;
				vm.data.transactionNoGuiadaList = [];
				vm.languageColumn = Language.getActiveColumn();
				vm.transactionsTypes = [];
				vm.expirationTypes = apiAdmin.expirationTypeList;
				vm.tramitationPhase = GlobalDataFactory.tramitationPhase;
				vm.daysComputed = [
                	{id:true, description:$filter("translate")("global.literals.yes")},
                	{id:false, description:$filter("translate")("global.literals.no")}
                ];
				vm.nextInfo = {};
				vm.responsibleUserBoolean = false;
				vm.haveFinalTransaction = false
				vm.showUsersProfiles = (($rootScope.app && $rootScope.app.configuration &&  $rootScope.app.configuration.assign_responsible_user && $rootScope.app.configuration.assign_responsible_user.dossierTransaction && 
							($rootScope.app.configuration.assign_responsible_user.dossierTransaction == 'Obligatoria' || $rootScope.app.configuration.assign_responsible_user.dossierTransaction == 'Opcional')) || $rootScope.esetMode);
				vm.amendmentFiscalizationTransactionTypeId = (($rootScope.app && $rootScope.app.configuration &&  $rootScope.app.configuration.secretary_properties && $rootScope.app.configuration.secretary_properties.fiscalizationAmmendment)?$rootScope.app.configuration.secretary_properties.fiscalizationAmmendment:undefined);
				vm.amendmentFiscalizationTransactionTypeFreeId = (($rootScope.app && $rootScope.app.configuration &&  $rootScope.app.configuration.secretary_properties && $rootScope.app.configuration.secretary_properties.fiscalizationAmmendmentFree)?$rootScope.app.configuration.secretary_properties.fiscalizationAmmendmentFree:undefined);
				if(vm.data && vm.data.dossier){
					if(TramNewFactory.isResponsible(vm.data.dossier)){
						vm.responsibleUserBoolean = true;
					}
					var finalTransactions = $linq(vm.data.dossier.dossierTransactions).where("x => x.transactionType != null && x.transactionType.finallyType == true").toArray();
					if (finalTransactions && finalTransactions.length > 0) {
                    	_.forEach(finalTransactions, function(value){
							if(vm.data.dossier.procedure && !vm.data.dossier.procedure.guided){
								if(!value.subprocedure){
									if(value.endDate == null){
			                    		vm.haveFinalTransaction = true;
			                    	}
								}
							}else{
	                    		if(value.endDate == null){
	                    			vm.haveFinalTransaction = true;
	                    		}
							}
                    	});
                    }
				}
				
				vm.createTransactionTypeList();
				
            }
        }],
        bindings: {
            data: '='
        }
    })